const mutations = {
	setLoading(state, payload) {
		state.loading = payload
	},
	setVoucher(state, payload) {
		state.voucher = payload;
	},
	setSelectedVoucher(state, payload) {
		payload.value_discount = payload.value_discount ? payload.value_discount / 100 : null;
		payload.start_at = payload.start_at ? new Date(payload.start_at) : null;
		payload.expire_at = payload.expire_at ? new Date(payload.expire_at) : null;
		state.selectedVoucher = payload;
	},
}

export default mutations
