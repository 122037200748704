<template lang='pug'>
dashboard
</template>

<script>
import DashboardView from './Dashboard/DashboardView.vue'

export default {
	components: {
		'dashboard': DashboardView
	},
}
</script>
