<template lang='pug'>
p-toast

</template>

<script>
    import eventTypes  from "@/events/events"

    export default {
        methods: {
            async showToastNotification(toastData) {
                this.$toast.add({
                    severity: toastData.severity,
                    summary: toastData.summary,
                    detail: toastData.detail,
                    life: 4000})
            }
        },
        mounted() {
            this.$bus.on(eventTypes.NOTIFY, this.showToastNotification)
        },
        beforeUnmount () {
            this.$bus.off(eventTypes.NOTIFY, this.showToastNotification)
        }
    }
</script>
