import { req } from '@/utils'
import api from '@/utils/api'

const actions = {
	async fetchMetrics({ state, commit }, params) {
		const data = {
			countryCode: params?.countryCode ?? state.countryCode,
			monthly: params?.frequency === 'monthly' ?? true,
			start: params?.start ? params.start.toISOString() : null,
			end: params?.end ? params.end.toISOString() : null
		}

		return req(
			api.get('api/v1/metrics', { params: data }),
			response => {
				commit('setMetrics', response.data)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
  async fetchRealTimeMetrics({ state, commit }, data) {
    const params = {
      period: data?.period ?? 'all',
			countryCode: data?.countryCode ?? state.countryCode,
		}

		return req(
			api.get('api/v1/metrics/real-time', { params: params }),
			response => {
        commit('setRealTimeMetrics', {
          period: params.period,
          data: response.data
        })
			},
			isLoading => {
				commit('setIsRealTimeMetricsLoading', isLoading)
			}
		)
	}
}

export default actions
