import { createApp } from 'vue'
import App from './App.vue'
import components from '@/components'
import router from '@/router'
import store from '@/store'
import plugins from './plugins'
import ToastService from 'primevue/toastservice';
import 'vue-tel-input/vue-tel-input.css';

const app = createApp(App)

components.register(app)
plugins.register(app)

app.use(router)
app.use(store)
app.use(ToastService)

app.mount('#app')
