import axios from "axios"
import store from "@/store"
import eventTypes from "@/events/events"
import bus from '@/events'
import handle401 from "@/utils/errors/handle401"

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

const requestHandler = async (request) => {
	let accessToken = store.getters.accessToken
	if (accessToken)
		request.headers["Authorization"] = `Bearer ${accessToken}`
	
	request.headers['x-api-key'] = `${process.env.VUE_APP_API_KEY}`
	return request
}

const responseHandler = (response) => {
	return response
}

const errorHandler = (error) => {
	if (error.code === 'ERR_NETWORK') {
		bus.emit(eventTypes.NOTIFY, {
			severity: "error",
			summary: "Erro de conexão",
			detail: "Sua conexão pode estar com problema ou o servidor pode estar offline. Entre em contato com o administrador do sistema."
		})
	}
	else {
		switch(error.response.status) {
			case 401: // Unauthorized
				handle401(error)
				break;
			case 403: // Forbidden
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Você não tem permissão para realizar esta ação",
					detail: "Entre em contato com o administrador do sistema"
				})
				break;
			case 500: // Internal Server Error
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro interno do servidor",
					detail: "Entre em contato com o administrador do sistema"
				})
				break;
		}
	}


	return Promise.reject(error)
}

api.interceptors.request.use(
	(request) => requestHandler(request),
    (error) => errorHandler(error)
)

api.interceptors.response.use(
	(response) => responseHandler(response),
    (error) => errorHandler(error)
)

export default api
