import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
    countryCode: null,

	performanceIndicator: null,
	performanceGrowthWeekly: null,
	performanceGrowthDaily: null,
	loading: false
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
