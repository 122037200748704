import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
    async fetchLaminas({state, commit}) {
        const query = {
            ...state.laminasFilters,
            ...state.pagination
    }
        return req(
			api.get('/api/v1/material', { params: query }),
			response => {
				commit('setTotalItemsLaminas', response.data.total_items)
				commit('setLaminas', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
    },
	async fetchTags({state, commit}) {
        const query = {
            ...state.tagsFilters,
        }
        return req(
			api.get('/api/v1/tag', { params: query }),
			response => {
				commit('setTags', response.data)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
    },
	async addMaterial({ commit }, data) {
		const formData = new FormData();
		formData.append('type', data.type);
		formData.append('title', data.title);
		formData.append('value', data.value);
		formData.append('file', data.file, data.file.name);

		for (var index in data.idTags) {
			formData.append('idTags', data.idTags[index]);
		}

		if(data.thumbnail) {
			formData.append('thumbnail', data.thumbnail, data.thumbnail.name);
		}

		return req(
			api.post('/api/v1/material', formData),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Material Dietbox",
					detail: "Material adicionado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
    async deleteMaterial({ commit }, materialId) {
		return req(
			api.delete(`/api/v1/material/${materialId}`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Material Dietbox",
					detail: "Material removido com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
}

export default actions;
