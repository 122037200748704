import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
	filters: {
		search: null,
		foodId: null,
		nutritionistId: null,
		tableType: null,
	},
	pagination: {
		skip: 0,
		take: 25
	},
	totalItems: 0,
	totalCount: '-',
	foods: [],
	food: null,
	foodGroups: null,
	loading: false,
	loadingGroups: false,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
