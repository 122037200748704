import authGuard from "./auth-guard";

import Home from "@/views/HomeView.vue";

import { Role } from "@/utils/roles";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    meta: { shotAtMenu: true },
    beforeEnter: authGuard,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/LoginView.vue"),
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("@/views/UnauthorizedView.vue"),
    meta: { shotAtMenu: false },
  },
  {
    path: "/nutritionists",
    name: "Nutricionistas",
    component: () => import("@/views/Nutritionist/ListView.vue"),
    meta: { shotAtMenu: true, authorize: [Role.Admin, Role.CustomerService] },
    beforeEnter: authGuard,
  },
  {
    path: "/nutritionists/:id",
    name: "Nutricionista",
    component: () => import("@/views/Nutritionist/IndexView.vue"),
    meta: { shotAtMenu: false, authorize: [Role.Admin, Role.CustomerService] },
    beforeEnter: authGuard,
  },
  {
    path: "/patients",
    name: "Pacientes",
    component: () => import("@/views/PatientView.vue"),
    meta: { shotAtMenu: true, authorize: [Role.Admin, Role.CustomerService] },
    beforeEnter: authGuard,
  },
  {
    path: "/foods",
    name: "Alimentos",
    component: () => import("@/views/Foods/FoodsListView.vue"),
    meta: { shotAtMenu: true, authorize: [Role.Admin] },
    beforeEnter: authGuard,
  },
  {
    path: "/foods/new",
    name: "Adicionar Alimento",
    component: () => import("@/views/Foods/FoodsView.vue"),
    meta: { authorize: [Role.Admin] },
    beforeEnter: authGuard,
  },
  {
    path: "/foods/:id",
    name: "Editar Alimento",
    component: () => import("@/views/Foods/FoodsView.vue"),
    meta: { authorize: [Role.Admin] },
    beforeEnter: authGuard,
  },
  {
    path: "/users",
    name: "Usuários",
    component: () => import("@/views/UserView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin],
      category: "Configurações",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/transactions",
    name: "Transações",
    component: () => import("@/views/TransactionView.vue"),
    meta: { shotAtMenu: true, authorize: [Role.Admin, Role.CustomerService] },
    beforeEnter: authGuard,
  },
  {
    path: "/content/materials",
    name: "Materiais",
    component: () => import("@/views/Content/MaterialsView"),
    meta: { shotAtMenu: true, authorize: [Role.Admin], category: "Conteúdo" },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/vouchers",
    name: "Cupons",
    component: () => import("@/views/Admin/Voucher/ListView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.CustomerService],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/featured-banners",
    name: "Banners em Destaque",
    component: () => import("@/views/Marketing/FeaturedBannersView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.CustomerService],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/push-notifications",
    name: "Notificações Mobile",
    component: () => import("@/views/Marketing/PushNotificationsView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.CustomerService],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/events",
    name: "Eventos",
    component: () => import("@/views/Event/EventsView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.CustomerService],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/events/:id",
    name: "Evento",
    component: () => import("@/views/Event/EventAreaView.vue"),
    meta: {
      authorize: [Role.Admin, Role.CustomerService],
    },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/crm",
    name: "CRM",
    component: () => import("@/views/Crm/CrmView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.CustomerService],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  // {
  // 	path: "/subscriptionConfiguration",
  // 	name: "Limites de Planos",
  // 	component: SubscriptionConfigurationView,
  // 	meta: { shotAtMenu: true, authorize: [Role.Admin] },
  // 	beforeEnter: authGuard
  // },
  {
    path: "/marketing/gifts-calendar",
    name: "Calendário Mês do Nutri",
    component: () => import("@/views/Marketing/GiftsCalendarView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.Analyst],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/partnership-voucher",
    name: "Cupons de Parceiros",
    component: () => import("@/views/Marketing/PartnershipVouchersView.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.Analyst],
      category: "Marketing",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/content/customizable-cards",
    name: "Cards Personálizaveis",
    component: () => import("@/views/Content/CustomizableCards.vue"),
    meta: {
      shotAtMenu: true,
      authorize: [Role.Admin, Role.Analyst],
      category: "Conteúdo",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/clickup/schedule",
    name: "Escala do Clickup",
    component: () => import("@/views/Clickup/ResponsibleScaleView.vue"),
    meta: {
      shotAtMenu: false,
      authorize: [Role.Admin, Role.Analyst, Role.CustomerService],
      category: "Clickup",
    },
    beforeEnter: authGuard,
  },
];

export default routes;
