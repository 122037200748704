import { req } from "@/utils";
import api from "@/utils/api";
import bus from "@/events";
import eventTypes from "@/events/events";

const actions = {
	async fetchNutritionists({ state, commit }, query) {
		let params;
		if (query) {
			params = {
				...query,
			};
		} else {
			params = {
				...state.filters,
				...state.pagination,
			};
		}

		return req(
			api.get("/api/v1/nutritionist", { params: params }),
			(response) => {
				commit("setTotalItems", response.data.total_items);
				commit("setNutritionists", response.data.items);
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async fetchNutritionistStats({ commit }, nutritionistID) {
		return req(
			api.get(`/api/v1/nutritionist/${nutritionistID}/stats`),
			(response) => {
				commit("setStats", response.data);
			},
			(isLoading) => {
				commit("setIsLoadingNutritionistStats", isLoading);
			},
		);
	},
	async fetchSelectedNutritionist({ commit }, nutritionistID) {
		return req(
			api.get(`/api/v1/nutritionist/${nutritionistID}`),
			(response) => {
				commit("setSelectedNutritionist", response.data);
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async updateNutritionist({ commit }, data) {
		const body = {
			...data.body,
		};
		return req(
			api.put(`/api/v1/nutritionist/${data.id}`, body),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Atualização do Nutricionista",
					detail: "Nutricionista atualizado com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoadingUpdateNutritionist", isLoading);
			}, (error) => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro na Atualização do Nutricionista",
					detail: `Ocorreu erro de código ${error.response.status}. Consulte um administrador`,
				});
			},
		);
	},

	async updateNutritionistSubscription({ commit }, data) {
		const body = {
			...data.body,
		};
		return req(
			api.put(`/api/v1/nutritionist/${data.id}/subscription`, body),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Atualização da Assinatura Nutricionista",
					detail: "Nutricionista atualizado com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoadingUpdateNutritionist", isLoading);
			}, (error) => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro na Atualização da Assinatura Nutricionista",
					detail: `Ocorreu erro de código ${error.response.status}. Consulte um administrador`,
				});
			},
		);
	},

	async updateSubscriptionExpireAt({ commit }, data) {
		const body = {
			...data.body,
		};
		return req(
			api.put(`/api/v1/subscription/${data.id}/expire-at`, body),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Alterar Data de Expiração",
					detail: "Data atualizada com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoadingUpdateSubscriptionExpireAt", isLoading);
			},
		);
	},
	async updateInvoceStatus({ commit }, data) {
		const body = {
			...data.body,
		};
		return req(
			api.put(`/api/v1/invoice/${data.id}/status`, body),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Alterar Status da Fatura",
					detail: "Fatura Atualizada com Sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async updateInvoiceFaliedHandled({ commit }, data) {
		const body = {
			...data.body,
		};
		return req(
			api.put(`/api/v1/invoice-failed-logs/${data.id}/handled`, body),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Alterar Estado da Fatura com Erro",
					detail: "Fatura com Erro Resolvida",
				});
			},
			(isLoading) => {
				commit("setIsLoadingInvoiceFaliedHandled", isLoading);
			},
		);
	},
	async cancelSubscription({ commit, dispatch }, data) {
		return req(
			api.patch(`/api/v1/nutritionist/${data.id}/subscription/cancel`, {
				cancel: true,
				reason: data.reason,
			}),
			() => {
				dispatch("fetchSelectedNutritionist", data.id);
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cancelamento de Assinatura",
					detail: "Assinatura Cancelada Com Sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async inactivateSubscription({ commit, dispatch }, id) {
		return req(
			api.delete(`/api/v1/nutritionist/${id}/subscription`),
			() => {
				dispatch("fetchSelectedNutritionist", id);
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Inativação de Assinatura",
					detail: "Assinatura Inativada Com Sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async specialSubscription({ commit, dispatch }, data) {
		return req(
			api.patch(`/api/v1/nutritionist/${data.id}/subscription/special`, {
				special: data.special,
			}),
			() => {
				dispatch("fetchSelectedNutritionist", data.id);
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Acesso Especial",
					detail: data.special
						? "Este usuário agora possui acesso especial"
						: "Este usuário não possui mais acesso especial",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async updateNutritionistEmail({ commit, dispatch }, data) {
		return req(
			api.patch(`/api/v1/nutritionist/${data.id}/email`, {
				email: data.email,
			}),
			() => {
				dispatch("fetchSelectedNutritionist", data.id);
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Alteração de e-mail",
					detail: "E-mail do nutricionista alterado com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async migrateToTSPay({ commit }, nutritionistId) {
		return req(
			api.post(`/api/v1/nutritionist/${nutritionistId}/migrate-tspay`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "TSPay",
					detail: "Nutricionista migrado com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoadingTSPayMigration", isLoading);
			},
		);
	},
	async reactivateNutritionistCampaignAugust({ commit }, nutritionistId) {
		return req(
			api.post(`/api/v1/nutritionist/${nutritionistId}/reactivate-user-campaign-august`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Campanha de agosto",
					detail: "Nutricionista reativado para o trial",
				});
			},
			(isLoading) => {
				commit("setIsLoadingCache", isLoading);
			},
		);
	},
	async deleteCache({ commit }, nutritionistId) {
		return req(
			api.delete(`/api/v1/nutritionist/${nutritionistId}/cache`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cache do Nutricionista",
					detail: "Cache removido com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoadingCache", isLoading);
			},
		);
	},
	async reactivatePatientsB2c({ commit }, nutritionistId) {
		return req(
			api.post(`/api/v1/nutritionist/${nutritionistId}/reactivate-patients-b2c`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Pacientes reativados",
					detail: "Pacientes do nutricionista foram reativados",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async deleteNutritionist({ commit }, nutritionistId) {
		return req(
			api.delete(`/api/v1/nutritionist/${nutritionistId}`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Deletar Nutricionista",
					detail: "Nutricionista removido com sucesso",
				});
			},
			(isLoading) => {
				commit("setIsLoading", isLoading);
			},
		);
	},
	async resetLoginCredentials({ commit }, nutritionistId) {
		return req(
			api.put(`/api/v1/nutritionist/${nutritionistId}/reset-login`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Login do nutricionista RESETADO",
					detail: "Nutritionista deve renovar a senha",
				});
			},
			(isLoading) => {
				commit("setIsLoadingCache", isLoading);
			},
		);
	},
	async fetchUniversities({ state, commit }, query) {
		let params;
		if(query) {
			params = {
				...query
			}
		} else {
			params = {
				...state.universityFilters
			}
		}

		return req(
			api.get('/api/v1/university', { params : params }),
			(response) => {
				commit("setUniversities", response.data);
			},
			(isLoading) => {
				commit("setIsLoadingUniversity", isLoading);
			},
		);
	},
	async fetchInvoicesFailed({ state, commit }) {
		const query = {
			...state.filtersInvoiceFalied,
			...state.pagination,
		};
		return req(
			api.get(`/api/v1/invoice-failed-logs`, { params: query }),
			(response) => {
				commit("setInvoicesFalied", response.data.items);
				commit("setTotalItemsInvoicesFalied", response.data.total_items);
			},
			(isLoading) => {
				commit("setIsLoadingInvoicesFalied", isLoading);
			},
		);
	},
};

export default actions;
