import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import event from '@/events/events'

const actions = {
	changeCountryCode({commit}, data) {
		commit('setCountryCode', data)		
		bus.emit(event.COUNTRY_CHANGED, data)
	},

	async fetchDashboard({ state, commit }, countryCode) {
		const data = { 
			CountryCode: countryCode ?? state.countryCode 
		}		
		return req(
			api.get('api/v1/report/performance-indicator', { params: data }),
			response => {
				commit('setPerformanceIndicator', response.data)								
			},
			isLoading => {
				commit('setIsLoading', isLoading)				
			}
		)
	},

	async fetchGrowthWeekly({ state, commit }, countryCode) {
		const data = { 
			CountryCode: countryCode ?? state.countryCode 
		}		
		return req(
			api.get('api/v1/report/performance-growth-weekly', { params: data }),
			response => {
				commit('setPerformanceGrowthWeekly', response.data)								
			},
			isLoading => {
				commit('setIsLoading', isLoading)				
			}
		)
	},

	async fetchGrowthDaily({ state, commit }, countryCode) {
		const data = { 
			CountryCode: countryCode ?? state.countryCode 
		}		
		return req(
			api.get('api/v1/report/performance-growth-daily', { params: data }),
			response => {
				commit('setPerformanceGrowthDaily', response.data)								
			},
			isLoading => {
				commit('setIsLoading', isLoading)				
			}
		)
	},


}

export default actions;