import { req } from '@/utils'
import bus from '@/events'
import api from '@/utils/api'
import eventTypes from "@/events/events"

const actions = {
	setLoading({ commit }, payload) {
		commit('setLoading', payload)
	},
	async addVoucher({ commit }, data) {
		return req(
			api.post(`/api/v1/voucher`, data),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cupom Dietbox",
					detail: "Cupom adicionado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			},
		)
	},
	async updateVoucher({ commit }, data) {
		return req(
			api.put(`/api/v1/voucher`, data),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Atualização do Evento",
					detail: "Evento atualizado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	fetchVouchers({ commit }, query) {
		return req(
			api.get('/api/v1/voucher', { params: query }),
			() => { },
			isLoading => {
				commit('setLoading', isLoading)
			}
		)
	},
	async deleteVoucher({ commit }, id) {
		return req(
			api.delete(`/api/v1/voucher/${id}`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cupons",
					detail: "Cupom removido com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchVoucher({ commit }, id) {
		return req(
			api.get(`/api/v1/voucher/${id}`),
			response => {
				commit("setSelectedVoucher", response.data)
			},
		)
	},
	fetchVoucherUsage({ commit }, query) {
		return req(
			api.get('/api/v1/voucher/usage', { params: query }),
			() => { },
			isLoading => {
				commit('setLoading', isLoading)
			}
		)
	}
}

export default actions;
