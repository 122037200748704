const mutations = {
	setPaginationSkip(state, payload) {
		state.pagination.skip = payload
	},
	setPaginationTake(state, payload) {
		state.pagination.take = payload
	},
	setTotalItems(state, payload) {
		state.totalItems = payload
	},
	setFoods(state, payload) {
		state.foods = payload
	},
	setIsLoading(state, payload) {
		state.loading = payload
	},
	setFoodActive(state, payload) {
		const index = state.foods.findIndex(food => food.id === payload.foodId)
		state.foods[index].active = payload.active
	},
	setFilter(state, payload) {
		state.filters = payload
	},
	setFood(state, payload) {
		state.food = payload
	},
	setFoodGroups(state, payload) {
		state.foodGroups = payload
	},
	setIsLoadingGroups(state, payload) {
		state.loadingGroups = payload
	},
	setTotalCount(state, payload) {
		state.totalCount = payload
	}
}

export default mutations
