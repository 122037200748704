const mutations = {
    setLaminasFilter(state, payload){
		state.laminasFilters = payload
	},
    setTagsFilter(state, payload){
		state.tagsFilters = payload
	},
    setIsLoading(state, payload){
        state.isLoading = payload
    },
    setPaginationSkip(state, payload) {
		state.pagination.skip = payload
	},
	setPaginationTake(state, payload) {
		state.pagination.take = payload
	},
    setLaminas(state, payload){
        state.laminas = payload
    },
    setTags(state, payload){
        state.tags = payload
    },
    setTotalItemsLaminas(state, payload){
        state.total_items_laminas = payload
    },
}

export default mutations