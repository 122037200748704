const mutations = {
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setMarketingEvent(state, payload) {
    state.marketingEvent = payload;
  },
  setSelectedEvent(state, payload) {
    state.selectedEvent = payload;
  },
  setSystemAreas(state, payload) {
    state.systemAreas = payload;
  },
  setEventArea(state, payload) {
    state.eventArea = payload;
  },
  setSelectedEventArea(state, payload) {
    state.selectedEventArea = payload
  }
}

export default mutations
