<template lang='pug'>
div
  .grid
    .col-12
      .grid.grid-nogutter
        .col.text-left
          h5 Metas
        .col.text-right
          p-dropdown.w-2(
            v-model="country",
            :options="countryOptions",
            optionLabel="name",
            optionValue="code",
            placeholder="Todos os países",
            @change="handleChangeCountry"
          )
      .grid.shadow-2.p-2.mt-2
        .col
          card(
            title="Meta Profissionais",
            :content="getCurrentProfessionalGoal.toLocaleString('pt-BR')",
            :isLoading="realTimeIsLoading"
          )
        .col
          card(
            title="Profissionais",
            :content="getCurrentPayingProfessional.toLocaleString('pt-BR')",
            color="#6ABD6E",
            :isLoading="realTimeIsLoading"
          )
        .col
          card(
            title="Quantos faltam",
            :content="(getDifferenceFromProfessionalGoal).toLocaleString('pt-BR')",
            :color="getDifferenceFromProfessionalGoal === 0 ? '#6ABD6E' : 'red'",
            :isLoading="realTimeIsLoading"
          )
        p-divider
        .col
          card(
            title="Meta Estudantes",
            :content="getCurrentStudentGoal.toLocaleString('pt-BR')",
            :isLoading="realTimeIsLoading"
          )
        .col
          card(
            title="Estudantes",
            :content="getCurrentPayingStudents.toLocaleString('pt-BR')",
            color="#24A0FF",
            :isLoading="realTimeIsLoading"
          )
        .col
          card(
            title="Quantos faltam",
            :content="(getDifferenceFromStudentGoal).toLocaleString('pt-BR')",
            :color="getDifferenceFromStudentGoal === 0 ? '#6ABD6E' : 'red'",
            :isLoading="realTimeIsLoading"
          )

  .grid.mt-2
    .col-12
      real-time-metrics-panel(
        title="Métricas Gerais",
        :data="generalMetrics",
        :is-loading="realTimeIsLoading"
      )
      real-time-metrics-panel(
        title="Métricas Profissionais - Mês Atual",
        :data="professionalMetricsCurrentMonth",
        :is-loading="realTimeIsLoading"
      )
      real-time-metrics-panel(
        title="Métricas Estudantes - Mês Atual",
        :data="studentsMetricsCurrentMonth",
        :isLoading="realTimeIsLoading"
      )

    .col-12.mt-4
      performance-metrics-panel
</template>

<script>
import config from "@/config";
import RealTimeMetricsPanel from "@/components/Dashboard/RealTimeMetricsPanel.vue";
import PerformanceMetricsPanel from "@/components/Dashboard/PerformanceMetricsPanel.vue";

import Vuex from "vuex";
const metrics = Vuex.createNamespacedHelpers("metrics");

export default {
  data() {
    return {
      dates: null,
      lastPeriodFrequency: "monthly",
      userType: "nutritionist",
      periodFrequency: "monthly",
      userTypeOptions: [
        { name: "Nutricionista", value: "nutritionist" },
        { name: "Estudante", value: "student" },
      ],
      periodFrequencyOptions: [
        { name: "Mês", value: "monthly" },
        { name: "Dia", value: "daily" },
      ],
      country: { name: "Todos os Países", code: null },
      countryOptions: [
        { name: "Todos os Países", code: null },
        { name: "Brasil", code: "BR" },
        { name: "Chile", code: "CL" },
        { name: "México", code: "MX" },
      ],
      monthlyGrowthGoal: 2,
      dailyGrowthGoal: 0.000657,
    };
  },
  components: {
    "real-time-metrics-panel": RealTimeMetricsPanel,
    "performance-metrics-panel": PerformanceMetricsPanel
  },

  computed: {
    ...metrics.mapGetters({
      realTimeIsLoading: "getRealTimeIsLoading",
      realTime: "getRealTime",
      metrics: "getMetrics",
      isLoading: "getIsLoading",
    }),
    getCurrentPayingProfessional() {
      return this.realTime.all?.count_paying_professionals ?? 0;
    },
    getCurrentPayingStudents() {
      return this.realTime.all?.count_paying_students ?? 0;
    },
    getDifferenceFromProfessionalGoal() {
      var difference = this.getCurrentProfessionalGoal - this.getCurrentPayingProfessional;
      if (difference < 0) return 0;
      return difference;
    },
    getDifferenceFromStudentGoal() {
      var difference = this.getCurrentStudentGoal - this.getCurrentPayingStudents;
      if (difference < 0) return 0;
      return difference;
    },
    getCurrentProfessionalGoal() {
      const date = new Date();
      return config.goals.professional[date.getFullYear()][date.getMonth() + 1]
    },
    getCurrentStudentGoal() {
      const date = new Date();
      return config.goals.student[date.getFullYear()][date.getMonth() + 1]
    },
    generalMetrics() {
      return [
        {
          title: 'Gratuitos',
          content: (this.realTime.all?.count_basic_professionals + this.realTime.all?.count_basic_students).toLocaleString('pt-BR'),
          color: 'black',
        },
        {
          title: 'Assinantes Totais',
          content: (this.realTime.all?.count_paying_professionals + this.realTime.all?.count_paying_students).toLocaleString('pt-BR'),
          color: '#6ABD6E',
        },
        {
          title: 'Expirações',
          content: (this.realTime.all?.count_expired_professionals + this.realTime.all?.count_expired_students).toLocaleString('pt-BR'),
          color: 'orange',
        },
        {
          title: 'Cancelamentos',
          content: (this.realTime.all?.count_canceled_professionals + this.realTime.all?.count_canceled_students).toLocaleString('pt-BR'),
          color: 'red',
        },
        {
          title: 'Especiais',
          content: this.realTime.all?.count_specials.toLocaleString('pt-BR'),
          color: '#9C27B0',
        },
        {
          title: 'LT Profissionais',
          content: this.realTime.all?.avg_professional_average_lifetime?.toLocaleString('pt-BR') ?? '-',
          color: 'black',
        },
        {
          title: 'LT Estudantes',
          content: this.realTime.all?.avg_student_average_lifetime?.toLocaleString('pt-BR') ?? '-',
          color: 'black',
        }
      ]

    },
    professionalMetricsCurrentMonth() {
      return [
        {
          title: 'Novos Cadastros',
          content: this.realTime.monthly?.count_basic_professionals.toLocaleString('pt-BR'),
        },
        {
          title: 'Novos Assinantes',
          content: this.realTime.monthly?.count_new_paying_professionals.toLocaleString('pt-BR'),
          color: '#24A0FF',
        },
        {
          title: 'Retornos',
          content: this.realTime.monthly?.count_returned_professionals.toLocaleString('pt-BR'),
          color: 'teal',
        },
        {
          title: 'Expirações',
          content: this.realTime.monthly?.count_expired_professionals.toLocaleString('pt-BR'),
          color: 'orange',
        },
        {
          title: 'Cancelamentos',
          content: this.realTime.monthly?.count_canceled_professionals.toLocaleString('pt-BR'),
          color: 'red',
        },
      ]
    },
    studentsMetricsCurrentMonth() {
      return [
        {
          title: 'Novos Cadastros',
          content: this.realTime.monthly?.count_basic_students.toLocaleString('pt-BR'),
        },
        {
          title: 'Novos Assinantes',
          content: this.realTime.monthly?.count_new_paying_students.toLocaleString('pt-BR'),
          color: '#24A0FF',
        },
        {
          title: 'Retornos',
          content: this.realTime.monthly?.count_returned_students.toLocaleString('pt-BR'),
          color: 'teal',
        },
        {
          title: 'Expirações',
          content: this.realTime.monthly?.count_expired_students.toLocaleString('pt-BR'),
          color: 'orange',
        },
        {
          title: 'Cancelamentos',
          content: this.realTime.monthly?.count_canceled_students.toLocaleString('pt-BR'),
          color: 'red',
        },
      ]
    }
  },

  methods: {
    ...metrics.mapActions(["fetchRealTimeMetrics", "fetchMetrics"]),
    getPeriod(dateStr) {
      const date = new Date(dateStr);
      if (this.periodFrequency === "monthly")
        return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}`;
      else
        return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
    },
    getChurn(item) {
      try {
        const index = this.metrics.findIndex(
          (x) => x.period === item.data.period
        );

        if (index < this.metrics.length - 1) {
          const lastMetrics = this.metrics[index + 1];
          return `${(
            (item.data.cancellations / lastMetrics.total_subscribers) *
            100
          ).toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}%`;
        } else {
          return `${(
            (item.data.cancellations / item.data.total_subscribers) *
            100
          ).toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}%`;
        }
      } catch (err) {
        return "-";
      }
    },
    exportCSV() {
      this.$refs.metricsDT.exportCSV();
    },
    handleChangeCountry(event) {
      this.fetchRealTimeMetrics({ period: 'all', countryCode: this.country });
      this.fetchRealTimeMetrics({ period: 'monthly', countryCode: this.country });
      this.fetchMetrics({
        countryCode: this.country,
        frequency: event.value,
        start: this.dates ? this.dates[0] : null,
        end: this.dates ? this.dates[1] : null,
      });
    },
    handleChangeFrequency(event) {
      if (event.value === this.lastPeriodFrequency) return;
      this.lastPeriodFrequency = event.value;
      this.fetchMetrics({
        countryCode: this.country,
        frequency: event.value,
        start: this.dates ? this.dates[0] : null,
        end: this.dates ? this.dates[1] : null,
      });
    },
    refreshMetrics() {
      this.fetchMetrics({
        countryCode: this.country,
        frequency: this.lastPeriodFrequency,
        start: this.dates ? this.dates[0] : null,
        end: this.dates ? this.dates[1] : null,
      });
    },
    getGrowthPercent(item) {
      const index = this.metrics.findIndex(
        (x) => x.period === item.data.period
      );

      const currentSubscribers = parseInt(
        this.metrics[index].total_subscribers
      );
      let previousSubscribers = parseInt(this.metrics[index].total_subscribers);

      const previousMonthIndex = index + 1;

      if (previousMonthIndex < this.metrics.length) {
        previousSubscribers = parseInt(
          this.metrics[previousMonthIndex].total_subscribers
        );
      }

      const growth =
        parseFloat(
          (currentSubscribers - previousSubscribers) / previousSubscribers
        ) * 100;

      if (this.periodFrequency == "monthly") {
        return `${growth.toFixed(2)}%`;
      } else {
        return `${growth.toFixed(4)}%`;
      }
    },
    getGrowthClass(item) {
      const index = this.metrics.findIndex(
        (x) => x.period === item.data.period
      );

      const currentSubscribers = parseInt(
        this.metrics[index].total_subscribers
      );
      let previousSubscribers = parseInt(this.metrics[index].total_subscribers);

      const previousMonthIndex = index + 1;

      if (previousMonthIndex < this.metrics.length) {
        previousSubscribers = parseInt(
          this.metrics[previousMonthIndex].total_subscribers
        );
      }

      const growth = parseFloat(currentSubscribers / previousSubscribers) - 1;

      let growthClass;
      const goal =
        this.periodFrequency == "monthly"
          ? this.monthlyGrowthGoal
          : this.dailyGrowthGoal;

      if (growth > 0) {
        growthClass = "text-green-500";

        if (growth > goal) {
          growthClass += " font-bold";
        }
      } else {
        growthClass = "text-red-300";
      }

      return growthClass;
    },
  },

  mounted() {
    this.fetchRealTimeMetrics({ period: 'all' });
    this.fetchRealTimeMetrics({ period: 'monthly' });
    this.fetchMetrics({
      frequency: this.periodFrequency,
    });
  },

  beforeUnmount() {},
};
</script>
