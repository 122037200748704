import { createStore } from 'vuex'
import { req } from '@/utils'
import api from '@/utils/api'
import router from '@/router'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

export default createStore({
    state: {
        isLoading: false,
        user: {},
        nutritionistImpersonated: {},
        patientImpersonated: {},
        access_token: '',
        refresh_token: '',
        expiration: Date.now()
    },
    plugins: [createPersistedState({
        paths: ['user', 'access_token', 'refresh_token', 'expiration', 'nutritionistImpersonated', 'patientImpersonated'],
        key: '1a3ca054-370c-441c-9120-48147231d5d1',
        storage: {
            getItem: key => { let cookies = Cookies.get(key); return cookies; },
            setItem: (key, value) => Cookies.set(key, value, { expires: 1 * 7, secure: true }),
            removeItem: key => Cookies.remove(key)
        }
    })],
    getters: {
        user(state) {
            return state.user
        },
        nutritionistImpersonated(state) {
            return state.nutritionistImpersonated
        },
        patientImpersonated(state) {
            return state.patientImpersonated
        },
        accessToken(state) {
            return state.access_token
        },
        refreshToken(state) {
            return state.refresh_token
        },
        isLoading(state) {
            return state.isLoading
        },
        isAuthenticated(state) {
            return state.access_token.length > 0 && (new Date(state.expiration) > Date.now())
        }
    },
    mutations: {
        setToken(state, payload) {
            state.user = payload.user
            state.access_token = payload.access_token
            state.refresh_token = payload.refresh_token
            state.expiration = payload.expires
        },
        clearToken(state) {
            state.user = {}
            state.access_token = ''
            state.refresh_token = ''
            state.expiration = null
            state.nutritionistImpersonated = {}
            state.patientImpersonated = {}
        },
        setLoading(state, payload) {
            state.isLoading = payload
        },
        setNutritionistImpersonated(state, payload) {
            state.nutritionistImpersonated = payload
        },
        setPatientImpersonated(state, payload) {
            state.patientImpersonated = payload
        }
    },
    actions: {
        signIn: async ({ commit }, model) => {
            return req(
                api.post('/api/v1/accounts/sign-in', model),
                response => {
                    commit('setToken', response.data)
                    router.push({ path: '/' })
                },
                isLoading => commit('setLoading', isLoading),
                () => { alert('E-mail ou senha inválidos') }
            )
        },
        signOut: ({ commit }) => {
            commit('clearToken')

            router.push('/login')
            Cookies.remove('1a3ca054-370c-441c-9120-48147231d5d1')
        },
        refreshToken: ({ commit, dispatch }, model) => {
            return req(
                api.post('/api/v1/accounts/refresh-token', model),
                response => {
                    commit('setToken', response.data)
                },
                isLoading => commit('setLoading', isLoading),
                () => {
                    dispatch('signOut')
                }
            )
        },
        async impersonateNutritionist({ commit }, nutritionistImpersonated) {
            return req(
                api.post(`/api/v1/nutritionist/impersonated`, {id_nutritionist_impersonated: nutritionistImpersonated.id}),
                () => {
                    commit('setNutritionistImpersonated', nutritionistImpersonated)
                },
                isLoading => commit('setLoading', isLoading)
            )
        },
        async unimpersonateNutritionist({ commit }) {
            return req(
                api.delete(`/api/v1/nutritionist/impersonated`),
                () => {
                    commit('setNutritionistImpersonated', null)
                },
                isLoading => commit('setLoading', isLoading)
            )
        },
        async fetchNutritionistImpersonated({ commit }) {
            return req(
                api.get(`/api/v1/nutritionist/impersonated/me`),
                response => {
                    if(response.data.length != 0)
                        commit('setNutritionistImpersonated', response.data)
                },
                isLoading => commit('setLoading', isLoading)
            )
        },

        async impersonatePatient({ commit }, patientImpersonated) {
            return req(
                api.post(`/api/v1/patient/impersonated`, {id_patient_impersonated: patientImpersonated.id}),
                () => {
                    commit('setPatientImpersonated', patientImpersonated)
                },
                isLoading => commit('setLoading', isLoading)
            )
        },
        async unimpersonatePatient({ commit }) {
            return req(
                api.delete(`/api/v1/patient/impersonated`),
                () => {
                    commit('setPatientImpersonated', null)
                },
                isLoading => commit('setLoading', isLoading)
            )
        },
        async fetchPatientImpersonated({ commit }) {
            return req(
                api.get(`/api/v1/patient/impersonated/me`),
                response => {
                    if(response.data.length != 0)
                        commit('setPatientImpersonated', response.data)
                },
                isLoading => commit('setLoading', isLoading)
            )
        },
    },
    modules
})
