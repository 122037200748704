const getters = {
	isLoading(state) {
		return state.loading
	},
	getVoucher(state) {
		return state.voucher;
	},
	getSelectedVoucher(state) {
		return state.selectedVoucher;
	}
}

export default getters
