import PrimeVue from 'primevue/config';

import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import CountryFlag from 'vue-country-flag-next';
import DataView from 'primevue/dataview';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import InlineMessage from 'primevue/inlinemessage';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Menubar from 'primevue/menubar';
import Sidebar from 'primevue/sidebar';
import SelectButton from 'primevue/selectbutton';
import TabMenu from 'primevue/tabmenu';
import ProgressBar from 'primevue/progressbar';
import Tag from 'primevue/tag';
import Row from 'primevue/row';
import Toolbar from 'primevue/toolbar';
import Calendar from 'primevue/calendar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Skeleton from 'primevue/skeleton';
import Steps from 'primevue/steps';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Image from 'primevue/image';
import Fieldset from 'primevue/fieldset';
import InputSwitch from 'primevue/inputswitch';


//#region COMMON
import card from './Common/Card.vue'
import InputNumber from 'primevue/inputnumber';
//#endregion

function register(app) {
	app.use(PrimeVue)
	app.use(DialogService);
	//add other components here
	app.component('p-dialog', Dialog)
	app.component('p-dialog-dynamic', DynamicDialog)
	app.component('p-input-number', InputNumber)
	app.component('p-dropdown', Dropdown)
	app.component('p-input-group', InputGroup)
	app.component('p-input-group-addon', InputGroupAddon)
	app.component('p-input-text', InputText)
	app.component('p-input-mask', InputMask)
	app.component('p-inline-message', InlineMessage)
	app.component('p-menubar', Menubar)
	app.component('p-sidebar', Sidebar)
	app.component('p-avatar', Avatar)
	app.component('p-selectbutton', SelectButton)
	app.component('p-button', Button)
	app.component('p-togglebutton', ToggleButton)
	app.component('p-tabmenu', TabMenu)
	app.component('p-progress-bar', ProgressBar)
	app.component('p-tag', Tag)
	app.component('p-dataview', DataView)
	app.component('p-checkbox', Checkbox)
	app.component('p-chart', Chart)
	app.component('p-card', Card)
	app.component('p-table', DataTable)
	app.component('p-column', Column)
	app.component('p-columngroup', ColumnGroup)
	app.component('p-row', Row)
	app.component('p-toolbar', Toolbar)
	app.component('p-divider', Divider)
	app.component('p-calendar', Calendar)
	app.component('p-message', Message)
	app.component('p-toast', Toast)
	app.component('p-country-flag', CountryFlag)
	app.component('p-skeleton', Skeleton)
	app.component('p-steps', Steps)
	app.component('p-text-area', Textarea)
	app.component('p-fileupload', FileUpload)
	app.component('p-multiselect', MultiSelect)
	app.component('p-autocomplete', AutoComplete)
	app.component('p-badge', Badge)
	app.component('p-color-picker', ColorPicker)
  app.component('p-image-preview', Image)
  app.component('p-fieldset', Fieldset)
	app.directive('tooltip', Tooltip)
	app.component('card', card)
	app.component('p-editor', Editor)
	app.component('p-input-switch', InputSwitch)
}

export default { register }
