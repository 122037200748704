const getters = {
	getCountryCode(state) {
		return state.countryCode
	},

	getPerformanceIndicator(state) {
		return state.performanceIndicator
	},

	getPerformanceGrowthWeekly(state) {
		return state.performanceGrowthWeekly
	},

	getPerformanceGrowthDaily(state) {
		return state.performanceGrowthDaily
	},

	getIsLoading(state) {
		return state.loading
	}
}

export default getters

