const mutations = {
	setPaginationSkip(state, payload) {
		state.pagination.skip = payload
	},
	setPaginationTake(state, payload) {
		state.pagination.take = payload
	},
	setTotalItems(state, payload) {
		state.totalItems = payload
	},
	setUsers(state, payload) {
		state.users = payload
	},
	setIsLoading(state, payload) {
		state.loading = payload
	}
}

export default mutations
