import { req } from '@/utils'
import router from '@/router'
import api from '@/utils/api'

const actions = {
	isRegistered: async({ commit }, email) => {
		return req(
			api.get(`/api/v1/accounts/is-registered/${email}`),
			() => {},
			isLoading => commit('setLoading', isLoading),
		)
	},
	fetchUsers: async({ state, commit }) => {
		const query = {
			...state.pagination
		}
		return req(
			api.get('/api/v1/accounts', { params: query }),
			response => {
				commit('setTotalItems', response.data.total_items)
				commit('setUsers', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	addUser: async ({ commit }, payload) => {
		return req(
			await api.post('/api/v1/accounts', payload),
			() => {},
			isLoading => commit('setIsLoading', isLoading)
		)
	},
	registerUser: async ({ commit }, model ) => {
		return req(
			api.put('/api/v1/accounts/register', model),
			response => {
				commit('setToken', response.data)
				router.push({ path: '/' })
			},
			isLoading => commit('setIsLoading', isLoading),
		)
	},
	updateUser: async({ commit }, data) => {
		return req(
			api.put(`/api/v1/accounts/${data.id}`, data),
			() => { },
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	deleteUser: async({ commit }, data) => {
		return req(
			api.delete(`/api/v1/accounts/${data.id}`),
			() => {},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	}
}

export default actions;
