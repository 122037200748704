import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from "@/events/events"
import { sanitizePayload, parseResponse } from '@/utils/foods'

const actions = {
	async fetchFoods({ state, commit }) {
		const params = {
			...state.filters,
			...state.pagination
		}

		return req(
			api.get('/api/v1/foods', { params }),
			response => {
				commit('setTotalItems', response.data.total_items)
				commit('setFoods', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchFoodsCount({ commit }) {
		return req(
			api.get('/api/v1/foods/count'),
			response => {
				commit('setTotalCount', response.data)
			},
		)
	},
	async fetchFood({ commit }, id) {
		return req(
			api.get(`/api/v1/foods/${id}`),
			response => {
				const parsedResponse = parseResponse(response.data)

				commit('setFood', parsedResponse)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async postFood({ commit }, data) {
		const sanitizedPayload = sanitizePayload(data)

		return req(
			api.post('/api/v1/foods', sanitizedPayload),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Gerenciar Alimentos",
					detail: "Alimento adicionado"
				})

				bus.emit(eventTypes.SAVE_FOOD_SUCCESS)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			},
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro ao salvar alimento",
					detail: "Entre em contato com a equipe de TI."
				})
			}
		)
	},
	async putFood({ commit }, data) {
		const sanitizedPayload = sanitizePayload(data)

		return req(
			api.put(`/api/v1/foods/${data.id}`, sanitizedPayload),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Gerenciar Alimentos",
					detail: "Alimento alterado"
				})

				bus.emit(eventTypes.SAVE_FOOD_SUCCESS)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			},
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro ao salvar alimento",
					detail: "Entre em contato com a equipe de TI."
				})
			}
		)
	},
	async patchFoodsActive({ commit }, data) {
		return req(
			api.patch(`/api/v1/foods/active`, data),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Gerenciar Alimentos",
					detail: "Alterado o Status dos Alimentos"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			},
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro ao salvar alimento",
					detail: "Entre em contato com a equipe de TI."
				})
			}
		)
	},
	async uploadCSV({ commit }, data) {
		const formData = new FormData();
		formData.append('file', data, data.name);

		return req(
			api.post(`/api/v1/foods/import`, formData),
			response => {
				bus.emit(eventTypes.SAVE_FOOD_SUCCESS, {
					severity: "success",
					summary: "Gerenciar Alimentos",
					detail: `Upload de ${response.data.count} alimentos concluído`
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async updateByCSV({ commit }, data) {
		const formData = new FormData();
		formData.append('file', data, data.name);

		return req(
			api.put(`/api/v1/foods/updateByImport`, formData),
			response => {
				bus.emit(eventTypes.SAVE_FOOD_SUCCESS, {
					severity: "success",
					summary: "Gerenciar Alimentos",
					detail: `Update de ${response.data.count} alimentos concluído`
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchFoodGroups({ commit }, data) {
		const defaultTableType = 3
		const tableType = parseInt(data) >= 0 ? data : defaultTableType

		return req(
			api.get(`/api/v1/food-groups?tableType=${tableType}`),
			response => {
				commit('setFoodGroups', response.data)
			},
			isLoading => {
				commit('setIsLoadingGroups', isLoading)
			}
		)
	},
	async postFoodGroup({ commit }, data) {
		return req(
			api.post('/api/v1/food-groups', data),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Gerenciar Alimentos",
					detail: "Grupo adicionado"
				})

				bus.emit(eventTypes.SAVE_FOOD_GROUP_SUCCESS)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			},
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "error",
					summary: "Erro ao salvar grupo de alimento",
					detail: "Entre em contato com a equipe de TI."
				})
			}
		)
	},
}

export default actions;
