export default {
    SHOW_LOADER: "SHOW_LOADER",
	CLICK_MENU: "CLICK_MENU",
    COUNTRY_CHANGED: 'COUNTRY_CHANGED',
    NOTIFY: "NOTIFY",
    SUBSCRIPTION_CHANGE: "SUBSCRIPTION_CHANGE",
	ASK_CONFIRMATION: "ASK_CONFIRMATION",
    SAVE_FOOD_SUCCESS: "SAVE_FOOD_SUCCESS",
    SAVE_FOOD_GROUP_SUCCESS: "SAVE_FOOD_GROUP_SUCCESS",
};

