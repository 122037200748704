const mutations = {
	setFilter(state, payload) {
		state.filters = payload
	},
	setPaginationSkip(state, payload) {
		state.pagination.skip = payload
	},
	setPaginationTake(state, payload) {
		state.pagination.take = payload
	},
	setTotalItems(state, payload) {
		state.totalItems = payload
	},
  setPurchaseLogs(state, payload) {
    state.purchaseLogs = payload;
  },
  setSentRecord(state, payload) {
    state.sentRecord = payload;
  },
	setIsLoading(state, payload) {
		state.isLoading = payload;
	},
	setNotificationLimit(state, payload) {
		state.notificationLimit = payload;
	},
}

export default mutations
