const getters = {
	getPagination(state) {
		return state.pagination
	},
	getTotalItems(state) {
		return state.totalItems
	},
	getTransactions(state) {
		return state.transactions
	},
	getIsLoading(state) {
		return state.loading
	}
}

export default getters
