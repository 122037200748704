import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
    isLoading: false,
    notificationsHistoryFilters: {
      search: null,
      skip: 0,
      take: 25
    },
    pagination: {
      skip: 0,
      take: 25
    },
    notificationsHistory: [],
    total_items_notifications_history: 0,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
