<template lang="pug">
.card.menu-topbar.p-3.surface-200
  .card-container.overflow-hidden
    .flex.justify-content-between
      button.p-link.layout-menu-button.text-600(
        v-if='isAuthenticated'
        @click='handleToggleMenu'
        class='hover:text-400'
        data-cy="btn-sidebar-menu"
      )
        i.pi.pi-bars.text-lg
      .right-content(v-if="isAuthenticated")
        p-button.mr-3.font-bold.mb-1(
          :loading="isLoading" 
          v-tooltip.bottom="nutritionistImpersonated?.email" 
          size='small' @click='handleClickUnimpersonateNutritionist()'
          v-if="showUnimpersonateNutritionist" 
          style="background: #62C700; border-color: #62C700"
          ) Desimpersonar nutricionista
        p-button.mr-3.font-bold.mb-1(:loading="isLoading" v-tooltip.bottom="patientImpersonated?.email" size='small' @click='handleClickUnimpersonatePatient()' v-if="showUnimpersonatePatient") Desimpersonar paciente
        //- RouterLink(to="/clickup/schedule")
        //-   ClickupResponsibleOfTheDay
        strong.text-green-500.text-lg.uppercase Portal Dietbox
</template>

<script>
import event from "@/events/events";
import Vuex from "vuex";
// import ClickupResponsibleOfTheDay from "../Clickup/ClickupResponsibleOfTheDay";

// import { RouterLink } from "vue-router";

export default {
  name: "AppNavBar",
  // components: {
  //   ClickupResponsibleOfTheDay,
  //   RouterLink,
  // },
  computed: {
    ...Vuex.mapGetters({
      isLoading: 'isLoading',
      nutritionistImpersonated: 'nutritionistImpersonated',
      isAuthenticated: "isAuthenticated",
      patientImpersonated: 'patientImpersonated'
    }),
    getNutritionistImpersonatedEmail() {
      return this.nutritionistImpersonated?.email
    },
    getPatientImpersonatedEmail() {
      return this.patientImpersonated?.email
    },
    showUnimpersonateNutritionist() {
      if (this.nutritionistImpersonated?.id == undefined) return false;
      return true;
    },
    showUnimpersonatePatient() {
      if (this.patientImpersonated?.id == undefined) return false;
      return true;
    },
  },
  methods: {
    ...Vuex.mapActions([
      'unimpersonatePatient',
      'unimpersonateNutritionist'
    ]),
    handleToggleMenu() {
      this.$bus.emit(event.CLICK_MENU);
    },
    handleClickUnimpersonateNutritionist() {
      this.unimpersonateNutritionist();
    },
    handleClickUnimpersonatePatient() {
      this.unimpersonatePatient();
    }
  },
};
</script>

<style lang="scss" scoped>
.p-sidebar-content {

  a,
  button {
    color: #69c300;
  }

  a:hover,
  button:hover {
    color: #68c30095;
  }
}
.custom-tooltip {
  white-space: nowrap; /* Impede a quebra de linha */
  max-width: none; /* Remove a largura máxima padrão */
  background: #69c300;
}

.menu-topbar {
  .right-content {
    display: flex;
    align-items: center;

    .responsible-of-the-day {
      margin-right: 1rem;
    }
  }
}
</style>
