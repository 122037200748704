import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  isLoading: false,
	gifts: [],
	partnershipVouchers: [],
  customizableCards: [],
  featuredBanners: [],
  planIdentifiers: [],
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
