const mutations = {
	setCountryCode(state, payload) {
		state.countryCode = payload
	},

	setPerformanceIndicator(state, payload) {
		state.performanceIndicator = payload
	},

	setPerformanceGrowthWeekly(state, payload) {
		state.performanceGrowthWeekly = payload
	},

	setPerformanceGrowthDaily(state, payload) {
		state.performanceGrowthDaily = payload
	},

	setIsLoading(state, payload) {
		state.loading = payload
	}
}

export default mutations
