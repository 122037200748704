import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  filter: {
    nutritionistId: null,
    emailNutritionist: null,
    emailCustomerService: null,
    scoreDisengagement: null,
    status: null,
    orderByExpireAt: false,
    sort: null
  },
  pagination: {
    skip: 0,
    take: 30
  },
  totalItems: 0,
  nutritionistEngagement: [],
  isLoading: false,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
