const mutations = {
	setIsRealTimeMetricsLoading(state, payload) {
		state.realTimeIsLoading = payload
	},
	setRealTimeMetrics(state, payload) {
    state.realTime[payload.period] = payload.data
	},
	setMetrics(state, payload) {
		state.metrics = payload
	},
	setIsLoading(state, payload) {
		state.isLoading = payload
	}
}

export default mutations
