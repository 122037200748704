import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  isLoading: false,
	filters: {
    eventType: null,
    start: null,
    end: null,
	},
	pagination: {
		skip: 0,
		take: 10
	},
	totalItems: 0,
  sentRecord: [],
  purchaseLogs: [],
	notificationLimit: {
		limit: 0,
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
