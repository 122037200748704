const getters = {
	getIsLoading(state) {
		return state.isLoading;
  },
  getMarketingEvent(state) {
    return state.marketingEvent;
  },
  getSelectedEvent(state) {
    return state.selectedEvent;
  },
  getSystemAreas(state){
    return state.systemAreas;
  },
  getEventArea(state) {
    return state.eventArea;
  },
  getSelectedEventArea(state) {
    return state.selectedEventArea;
  }
}

export default getters
