const mutations = {
	setFilter(state, payload) {
		state.filters = payload
	},
	setPaginationSkip(state, payload) {
		state.pagination.skip = payload
	},
	setPaginationTake(state, payload) {
		state.pagination.take = payload
	},
	setPatients(state, payload) {
		state.patient = payload
	},
	setIsLoading(state, payload) {
		state.loading = payload
	},
	setTotalItems(state, payload) {
		state.totalItems = payload
	},
}

export default mutations
