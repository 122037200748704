const getters = {
	getPagination(state) {
		return state.pagination
	},
	getTotalItems(state) {
		return state.totalItems
	},
	getFoods(state) {
		return state.foods
	},
	getIsLoading(state) {
		return state.loading
	},
	getFood(state) {
		return state.food
	},
	getFoodGroups(state) {
		return state.foodGroups
	},
	getIsLoadingGroups(state) {
		return state.loadingGroups
	},
	getTotalCount(state) {
		return state.totalCount
	}
}

export default getters
