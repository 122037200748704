import store from "@/store"

const authGuard = async (to, from, next) => {
	if (store.getters.isAuthenticated) {
		const { authorize } = to.meta

    if (!isAuthorized(authorize, store.state.user.role)) {
      return next({ path: 'unauthorized' })
    }

		return next()
  } else {
    // if not authenticated refresh token
    await store.dispatch('refreshToken', { 'refresh-token': store.state.refresh_token })
    if (store.getters.isAuthenticated) {
      const { authorize } = to.meta
      if (!isAuthorized(authorize, store.state.user.role)) {
        return next({ path: 'unauthorized' })
      }
      return next()
    }
  }

	return next({ path: 'login' })
}

//check if user is authorized
function isAuthorized(authorize, userRole) {
  if (authorize) {
    if (authorize.length && !authorize.includes(userRole)) {
      return false
    }
  }
  return true
}

export default authGuard;
