const getters = {
    getNotificationsHistory(state){
		return state.notificationsHistory
	},
	getTotalItems(state){
		return state.total_items_notifications_history
	},
	isLoading(state) {
		return state.loading
	}
}

export default getters