const getters = {
	getIsLoading(state) {
		return state.isLoading;
	},
	getSubscriptionConfiguration(state) {
		return state.subscriptionConfiguration;
	},
}

export default getters
