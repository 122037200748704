import getters from './getters'
import mutations from './mutations'
import actions from './actions'


const state = {
	realTimeIsLoading: false,
	realTime: {
    all: {
      count_basic_professionals: 0,
      count_basic_students: 0,
      count_paying_professionals: 0,
      count_paying_students: 0,
      count_expired_professionals: 0,
      count_expired_students: 0,
      count_canceled_professionals: 0,
      count_canceled_students: 0,
      count_specials: 0,
      avg_professional_average_lifetime: 0,
      avg_student_average_lifetime: 0,
    },
    monthly: {
      count_basic_professionals: 0,
      count_basic_students: 0,
      count_new_paying_professionals: 0,
      count_new_paying_students: 0,
      count_returned_professionals: 0,
      count_returned_students: 0,
      count_expired_professionals: 0,
      count_expired_students: 0,
      count_canceled_professionals: 0,
      count_canceled_students: 0,
    }
	},
	metrics: [],
	isLoading: false,
}



export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
