<template lang="pug">
p-sidebar(v-model:visible="show")
  .card.mb-0
    .card-container.text-center
      .app-menu
        //.app-menu__avatar
          p-avatar(icon='pi pi-user', shape='circle', size='large')
        .app-menu__data
          span.font-bold.mb-1 {{ user.email }}
          span.block.surface-border {{ user.role }}
      .block
        ul.list-none.p-0.text-left
          template(v-for='(category, index) in categories', :v-key='index')
            li.mt-4.p-2.surface-border
              span {{ category.name }}
            template(v-for='(route, index) in category.routes', :v-key='index')
              li.p-2.surface-border.border-top-1(v-if='route.meta.shotAtMenu')
                router-link.nav-link.no-underline(
                  :to='route.path'
                  :data-cy="`btn-menu-${route.name}`"
                ) {{ route.name }}
          li.mt-4.p-2
            button.p-link.no-underline(@click='logOut()')
              i.pi.pi-power-off.pr-2
              span Logout
</template>

<script>
import event from "@/events/events";

import Vuex from "vuex";


export default {
  name: "app-menu",
  data() {
    return {
      show: false,
      categories: [
        { name: "Geral", routes: [] },
        { name: "Conteúdo", routes: [] },
        { name: "Marketing", routes: [] },
        // { name: "Clickup", routes: [] },
        { name: "Configurações", routes: [] },
      ],
    };
  },
  computed: {
    ...Vuex.mapGetters({
      user: "user",
    })
  },
  methods: {
    ...Vuex.mapActions(["signOut"]),
    async logOut() {
      this.signOut();
    },
    groupRoutes(routes) {
      routes.forEach((route) => {
        if (route.meta.category) {
          this.categories.forEach((category) => {
            if (category.name === route.meta.category) {
              category.routes.push(route);
            }
          });
        } else {
          this.categories[0].routes.push(route);
        }
      });
    },
    showMenu(data) {
      if (data) {
        this.show = data.show;
      } else {
        this.show = !this.show;
      }
    }
  },
  async mounted() {
    this.groupRoutes(this.$router.getRoutes());
    this.$bus.on(event.CLICK_MENU, this.showMenu);
  },
  beforeUnmount() {
    this.$bus.off(event.CLICK_MENU, this.showMenu);
  },
};
</script>

<style lang="scss">
.app-menu {
  display: flex;
  align-items: center;

  &__data {
    margin-left: 1rem;
  }
  
}
</style>
