import { req } from '@/utils'
import notificationApi from '@/utils/notification-api'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
	async addNotificationLimit({ commit }, data) {
		return req(
			notificationApi.post(`/api/v1/nutritionists`, data),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Atualização do Nutricionista",
					detail: "Limite de notificações atualizado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchNotificationLimit({ commit }, data) {
		const { id, type } = data;

		return req(
			notificationApi.get(`/api/v1/nutritionists/${id}?type=${type}`),
			response => {
				if(response.data.length == 0) {
					commit('setNotificationLimit', {
						limit: 0,
						type
					})
				} else {
					commit('setNotificationLimit', response.data[0])
				}
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchPurchaseLogs({ state, commit }, data) {
		const query = {
			type: data.type,
		...state.filters,
		...state.pagination
		}

		return req(
			notificationApi.get(`/api/v1/nutritionists/${data.id}/logs`, { params: query }),
			response => {
				commit('setTotalItems', response.data.total_items)
				commit('setPurchaseLogs', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchSentRecord({ state, commit }, data) {

		const query = {
			nutritionist_id: data.id,
			type: data.type,
			...state.filters,
			...state.pagination
		}

		return req(
			notificationApi.get(`/api/v1`, { params: query }),
			response => {
				commit('setTotalItems', response.data.total_items)
				commit('setSentRecord', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
}

export default actions;
