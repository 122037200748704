import { req } from '@/utils'
import api from '@/utils/api'

const actions = {
	async fetchPatients({ state, commit }) {		
		const query = {
			...state.filters,
			...state.pagination
		}
		return req(
			api.get('/api/v1/patient', { params: query }),
			response => {
				commit('setPatients', response.data.items)
				commit('setTotalItems', response.data.total_items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	}
}

export default actions;
