import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
	filters: {
		search: null,
		emailNutritionist: null,
		gender: null,
		maritalStatus: null,
		isActive: false,
		isPregnant: false,
		idNutritionist: null,
        sort: null,
        orderBy: null,
	},
	pagination: {
		skip: 0,
		take: 10
	},
	totalItems: 0,
	patient: null,
	loading: false
}

export default {
    namespaced: true,
	state,
	getters,
	mutations,
	actions
}
