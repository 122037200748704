<template lang="pug">
notify
confirmation-dialog
app-nav-bar(@menu-toggle='handleMenuToggle')
app-menu(v-show='showMenu')
.layout-main
	router-view
app-footer
</template>

<script>
import AppNavBar from "./components/AppComponents/AppNavBar";
import AppFooter from "./components/AppComponents/AppFooter";
import AppMenu from '@/components/AppComponents/AppMenu.vue';
import NotifyToast from "./components/Common/NotifyToast";
import ConfirmationDialog from "./components/Common/ConfirmationDialog";

export default {
	components: {
		'app-footer': AppFooter,
		'app-nav-bar': AppNavBar,
		'app-menu': AppMenu,
		'notify': NotifyToast,
		'confirmation-dialog': ConfirmationDialog,
	},
	data() {
		return {
			showMenu: false
		}
	},
	methods: {
		handleMenuToggle() {
			this.showMenu = !this.showMenu
		},
	}
};
</script>

<style lang="scss">
@import "~primevue/resources/primevue.min.css";
@import "~primevue/resources/themes/saga-blue/theme.css";
@import "~primeflex/primeflex.css";
@import "~primeicons/primeicons.css";

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #232323;
}

body,
html {
	padding: 0;
	margin: 0;
	font-size: 14px;
}

* {
	box-sizing: border-box;
}
</style>
