const getters = {
	getRealTimeIsLoading(state) {
		return state.realTimeIsLoading
	},
	getRealTime(state) {
		return state.realTime
	},
	getMetrics(state) {
		return state.metrics
	},
	getIsLoading(state) {
		return state.isLoading
	}
}

export default getters
