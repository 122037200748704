<template lang="pug">
div
	.grid
		.col
			span {{ title }}
	.grid
		.col(v-if='!isLoading')
			span.text-2xl.font-bold(:style='{color: color}') {{ content }}
		.col(v-else='')
			.flex.justify-content-center.align-items
				p-skeleton(width='2rem', style="transform='translate(60%, 0px)")
</template>

<script>
export default {
	name: "Card",
	props: {
		title: {
			type: String,
			default: "Title"
		},
		content: {
			type: String,
			default: "Content"
		},
		color: {
			type: String,
			default: 'black'
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	}
}
</script>
