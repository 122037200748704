<template lang='pug'>
.layout-footer
	span.text-muted.px-4 {{ currentYear }} © Portal Dietbox
</template>

<script>
export default {
	name: "AppFooter",
	data () {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>
