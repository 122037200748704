const mutations = {
    setNotificationsHistory(state, payload){
		state.notificationsHistory = payload
	},
	setNotificationsHistoryFilter(state, payload){
		state.notificationsHistoryFilters = payload
	},
	setTotalItems(state, payload){
		state.total_items_notifications_history = payload
	},
	setIsLoading(state, payload) {
		state.loading = payload
	},
}

export default mutations