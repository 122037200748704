import getters from './getters'
import actions from './actions'
import mutations from './mutations'


const state = {
	filters: {
		nutritionistId: null,
		planIdentifier: null,
		transactionStatus: null,
		paymentMethod: null,
		paymentGateway: null,
        start: null,
        end: null,
	},
	pagination: {
		skip: 0,
		take: 100
	},
	totalItems: 0,
	transactions: [],
	loading: false
}

export default {
    namespaced: true,
	state,
	getters,
	mutations,
	actions
}
