import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
  async addEvent({ commit }, data) {
    return req(
      api.post(`/api/v1/events`, data),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Evento Dietbox",
          detail: "Evento adicionado com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

  async fetchEvents({ commit }, params) {
    return req(
      api.get('/api/v1/events', {params: params}),
      response => {
        commit("setMarketingEvent", response.data.items)
      },
      isLoading => {
        commit("setIsLoading", isLoading)
      }
    )
  },

  async fetchEvent({ commit }, id) {
    return req(
      api.get(`/api/v1/events/${id}`),
      response => {
        commit("setSelectedEvent", response.data)
      },
      isLoading => {
        commit("setIsLoading", isLoading)
      }
    )
  },

  async updateEvent({ commit }, data) {
		return req(
			api.put(`/api/v1/events/${data.id}`, data ),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Atualização do Evento",
					detail: "Evento atualizado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},

  async deleteEvent({ commit }, data) {
    return req(
      api.delete(`/api/v1/events/${data.id}`),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Evento",
          detail: "Evento removido com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

  async addEventArea({ commit }, data) {
    const formData = new FormData();
    formData.append('eventId', data.event_id)
    formData.append('text', data.text);
    formData.append('redirectLink', data.redirect_link);
    formData.append('backgroundColor', data.background_color);
    formData.append('systemArea', data.system_area);
    formData.append('isStudent', data.is_student);
    formData.append('isProfessional', data.is_professional);
    formData.append('isSubscriber', data.is_subscriber);
    formData.append('counterText', data.counter_text);
    formData.append('counterTextColor', data.counter_text_color);
    formData.append('isTextBanner', data.is_text_banner);
    formData.append('textBanner', data.text_banner);
    formData.append('textBannerColor', data.text_banner_color);

    if(data.image_xs) formData.append('fileImageXs', data.image_xs, data.image_xs.name);
    if(data.image_md) formData.append('fileImageMd', data.image_md, data.image_md.name);
    
    //not required
    if (data.frequency) formData.append('frequency', data.frequency);
      
    if(data.image_sm) formData.append('fileImageSm', data.image_sm, data.image_sm.name);
    
    if(data.image_lg) formData.append('fileImageLg', data.image_lg, data.image_lg.name);
    
    if(data.image_xg) formData.append('fileImageXg', data.image_xg, data.image_xg.name);

    return req(
      api.post('/api/v1/events/event-areas', formData),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Banner de Evento",
          detail: "Banner adicionado com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

  async fetchSystemAreas({ commit }) {
    return req(
      api.get('/api/v1/events/event-areas/types'),
      response => {
        commit("setSystemAreas", response.data)
      },
    )
  },
  
  async fetchEventAreas({ commit }, params) {
    return req(
      api.get('/api/v1/events/event-areas', {params: params}),
      response => {
        commit("setEventArea", response.data.items)
      },
      isLoading => {
        commit("setIsLoading", isLoading)
      }
    )
  }, 

  async fetchEventArea({ commit }, id) {
    return req(
      api.get(`/api/v1/events/event-areas/${id}`),
      response => {
        commit("setSelectedEventArea", response.data)
      },
    )
  }, 

  async removeEventArea({ commit }, id) {
    return req(
      api.delete(`/api/v1/events/event-areas/${id}`),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Área de Evento",
          detail: "Área de Evento removido com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

  async updateEventAreaOptions({ commit }, data) {
    return req(
      api.put(`/api/v1/events/event-areas/${data.id}`, data),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Área de Evento",
          detail: "Área de Evento alterado com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  }
}

export default actions;
