import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
	pagination: {
		skip: 0,
		take: 10
	},
	totalItems: 0,
	users: [],
	loading: false
}

export default {
    namespaced: true,
	state,
	getters,
	mutations,
	actions
}
