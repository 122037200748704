const adjustedPortion = 100

const calculatePortionProportion = (portion, value) => {
  if(!value) {
    return
  }

  if(portion == adjustedPortion) {
    return value
  }

  const adjustedValue = (value * adjustedPortion) / portion

  return adjustedValue
}

export const sanitizePayload = (data) => {
  const portion = data.portion

  return {
    active: data.active,
    description: data.description,
    table_type: data.table,
    equivalent_group_id: data.group,
    quantity: adjustedPortion,
    energy: calculatePortionProportion(portion, data.calories),
    protein: calculatePortionProportion(portion, data.proteins),
    carbohydrate: calculatePortionProportion(portion, data.carbohydrates),
    lipids: calculatePortionProportion(portion, data.lipids),
    total_sugar: calculatePortionProportion(portion, data.sugar),
    calcium: calculatePortionProportion(portion, data.calcium),
    cholesterol: calculatePortionProportion(portion, data.cholesterol),
    iron: calculatePortionProportion(portion, data.iron),
    fiber: calculatePortionProportion(portion, data.fiber),
    mono_saturated: calculatePortionProportion(portion, data.monoInsaturatedFats),
    poly_saturated: calculatePortionProportion(portion, data.polySaturatedFats),
    saturated: calculatePortionProportion(portion, data.saturatedFats),
    trans_fat: calculatePortionProportion(portion, data.transFats),
    phosphorus: calculatePortionProportion(portion, data.phosphorus),
    magnesium: calculatePortionProportion(portion, data.magnesium),
    manganese: calculatePortionProportion(portion, data.manganese),
    potassium: calculatePortionProportion(portion, data.potassium),
    selenium: calculatePortionProportion(portion, data.selenium),
    sodium: calculatePortionProportion(portion, data.sodium),
    vitamin_a: calculatePortionProportion(portion, data.vitaminA),
    thiamine: calculatePortionProportion(portion, data.thiamine),
    riboflavin: calculatePortionProportion(portion, data.riboflavin),
    niacin: calculatePortionProportion(portion, data.niacin),
    pyridoxine: calculatePortionProportion(portion, data.pyridoxine),
    folate: calculatePortionProportion(portion, data.folate),
    cobalamin: calculatePortionProportion(portion, data.cobalamin),
    vitamin_c: calculatePortionProportion(portion, data.vitaminC),
    vitamin_d: calculatePortionProportion(portion, data.vitaminD),
    vitamin_e: calculatePortionProportion(portion, data.vitaminE),
    zinc: calculatePortionProportion(portion, data.zinc),
    homemade_measures: data.homemadeMeasurements.map((item) => ({ ...item }))
  }
}

export const parseResponse = (data) => {
  return {
    id: data.id,
    active: data.active,
    description: data.description,
    table: data.table_type,
    group: data.equivalent_group_id,
    portion: data.quantity,
    calories: data.energy,
    proteins: data.protein,
    carbohydrates: data.carbohydrate,
    lipids: data.lipids,
    sugar: data.total_sugar,
    calcium: data.calcium,
    cholesterol: data.cholesterol,
    iron: data.iron,
    fiber: data.fiber,
    monoInsaturatedFats: data.mono_saturated,
    polySaturatedFats: data.poly_saturated,
    saturatedFats: data.saturated,
    transFats: data.trans_fat,
    phosphorus: data.phosphorus,
    magnesium: data.magnesium,
    manganese: data.manganese,
    potassium: data.potassium,
    selenium: data.selenium,
    sodium: data.sodium,
    vitaminA: data.vitamin_a,
    thiamine: data.thiamine,
    riboflavin: data.riboflavin,
    niacin: data.niacin,
    pyridoxine: data.pyridoxine,
    folate: data.folate,
    cobalamin: data.cobalamin,
    vitaminC: data.vitamin_c,
    vitaminD: data.vitamin_d,
    vitaminE: data.vitamin_e,
    zinc: data.zinc,
    homemadeMeasurements: data.homemade_measures
  }
}
