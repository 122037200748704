const mutations = {
	setIsLoading(state, payload) {
		state.isLoading = payload;
	},
	setSubscriptionConfiguration(state, payload) {
		state.subscriptionConfiguration = payload;
	},
}

export default mutations
