import axios from "axios"
import eventTypes from "@/events/events"
import bus from '@/events'

const notificationApi = axios.create({
	baseURL: process.env.VUE_APP_NOTIFICATION_API_URL,
  headers: {
    'x-api-key': process.env.VUE_APP_NOTIFICATION_API_KEY
  }
})

const responseHandler = (response) => {
	return response
}

const errorHandler = (error) => {
	if (error.code === 'ERR_NETWORK') {
		bus.emit(eventTypes.NOTIFY, {
			severity: "error",
			summary: "Erro de conexão",
			detail: "Sua conexão pode estar com problema ou o servidor pode estar offline. Entre em contato com o administrador do sistema."
		})
	}
	else {
		bus.emit(eventTypes.NOTIFY, {
			severity: "error",
			summary: "Erro interno do servidor",
			detail: "Entre em contato com o administrador do sistema"
		});
	}

	return Promise.reject(error)
}

notificationApi.interceptors.response.use(
	(response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default notificationApi
