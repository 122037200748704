const getters = {
	getFilter(state){
		return state.filters
	},
	getFiltersInvoicesFalied(state){
		return state.filtersInvoicesFalied
	},
	getPagination(state) {
		return state.pagination
	},
	getTotalItems(state) {
		return state.totalItems
	},
	getNutritionists(state) {
		return state.nutritionists
	},
	getSelectedNutritionist(state){
		return state.selectedNutritionist
	},
	getUniversities(state){
		return state.universities
	},
	getStats(state) {
		return state.stats
	},
	getIsLoading(state) {
		return state.loading
	},
	getIsLoadingUniversity(state) {
		return state.loadingUniversity
	},
	getIsLoadingUpdateNutritionist(state) {
		return state.loadingUpdatingNutritionist
	},
	getIsLoadingUpdateSubscriptionExpireAt(state) {
		return state.loadingSubscriptionExpireAt
	},
	getIsLoadingCancelSubscription(state) {
		return state.loadingCancelSubscription
	},
	getIsLoadingNutritionistStats(state) {
		return state.loadingGettingStats
	},
	getIsLoadingTSPayMigration(state) {
		return state.loadingTSPayMigration
	},
	getIsLoadingCache(state) {
		return state.loadingCache
	},
	getIsLoadingDeleteNutritionist(state) {
		return state.loadingDeleteNutritionist
	},
	getShowEditNutritionist(state) {
		return state.showEditNutritionist
	},
	getInvoicesFalied(state) {
		return state.invoicesFalied
	},
	getTotalItemsInvoicesFalied(state) {
		return state.totalItemsInvoicesFalied
	},
	getIsLoadingInvoicesFalied(state) {
		return state.loadingInvoicesFalied
	},
	getIsLoadingInvoiceFaliedHandled(state) {
		return state.loadingInvoiceFaliedHandled
	},
}

export default getters
