import { req } from '@/utils'
import apiCore from '@/utils/api-core'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
	async sendPushNotifications({ commit }, data) {
		
		const formData = new FormData();

		formData.append('title', data.title);
		formData.append('message', data.message);
		formData.append('responsible', data.responsible);
		formData.append('target', data.target);
		formData.append('excelFile', data.file, data.file.name);

		return req(
			apiCore.post('/v2/anonymous/notifications/send-by-excel', formData),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Notificações",
					detail: "Notificações enviadas com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchPushNotificationsHistory({state, commit}) {
		const query = {
				...state.notificationsHistoryFilters,
		}
		
		return req(
			apiCore.get('/v2/anonymous/notifications/history', { params: query }),
			response => {
				commit('setNotificationsHistory', response.data.Data.Items)
				commit('setTotalItems', response.data.Data.TotalItems)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	}
}

export default actions;
