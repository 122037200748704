const getters = {
	getIsLoading(state) {
		return state.isLoading;
	},
	getGifts(state) {
		return state.gifts;
	},
	getPartnershipVouchers(state) {
		return state.partnershipVouchers;
	},
	getCustomizableCards(state) {
		return state.customizableCards;
  },
  getFeaturedBanners(state) {
    return state.featuredBanners;
  },
  getFeaturedBannersTotalItems(state) {
    return state.featuredBannersTotalItems;
  },
  getPlanIdentifiers(state){
    return state.planIdentifiers;
  },
}

export default getters
