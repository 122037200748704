<template lang='pug'>
.grid.grid-nogutter.mt-4
  .col.text-left
    h5 {{ title }}
.grid.shadow-2.p-2.mt-2
  .col(v-for='item in data')
    card(
      :title='item.title',
      :content='item.content',
      :color='item.color',
      :is-loading='isLoading'
    )
</template>

<script>
import Vuex from "vuex";
const metrics = Vuex.createNamespacedHelpers("metrics");

export default {
  name: 'RealTimeMetricsPanel',
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...metrics.mapGetters({
      realTimeIsLoading: "getRealTimeIsLoading",
    })
  }
}

</script>

<style lang="scss"></style>
