import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
	filters: {
		name: null,
		email: null,
		cpf: null,
		subscriptionType: null,
		subscriptionStatus: null,
		sort: null,
		orderBy: null,
		justWithCancellationJustification: false
	},
	filtersInvoiceFalied: {
		start: null,
		end: null,
	},
	pagination: {
		skip: 0,
		take: 25
	},
	totalItems: 0,
	nutritionists: [],
	selectedNutritionist: null,
	universities: null,
	invoicesFalied: [],
	totalItemsInvoicesFalied: 0,
	stats: {
		total_foods: 0,
		total_menus: 0,
		total_anamnesis: 0,
		total_anthropometry: 0,
		total_patients: 0,
		total_appointments: 0,
		total_food_plans: 0,
		total_recipes: 0
	},
	loading: false,
	loadingUniversity: false,
	loadingGettingStats: false,
	loadingUpdatingNutritionist: false,
	loadingSubscriptionExpireAt: false,
	loadingDeleteNutritionist: false,
	loadingTSPayMigration: false,
	loadingCache: false,
	showEditNutritionist: false,
	loadingCancelSubscription: false,
	loadingInvoicesFalied: false,
	loadingInvoiceFaliedHandled: false,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
