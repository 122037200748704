import axios from "axios"
import store from "@/store"

let isRefreshing = false, refreshTokenRequest = null

const errorHandler = (error) => {
	let data = { 'refresh-token': store.state.refreshToken }

	if (isRefreshing) return refreshTokenRequest

  isRefreshing = true
  refreshTokenRequest = store.dispatch('refreshToken', data).then(response => {
		isRefreshing = false
		refreshTokenRequest = null
		error.config.headers['Authorization'] = `Bearer ${response.data.access_token}`
		return axios.request(error.config)
	})

	refreshTokenRequest.finally(() => {
		if (isRefreshing) {
			isRefreshing = false
			store.dispatch('signOut')
		}
	})
}

export default errorHandler
