const mutations = {
	setIsLoading(state, payload) {
		state.isLoading = payload;
	},
	setGifts(state, payload) {
		state.gifts = payload;
	},
	setPartnershipVouchers(state, payload) {
		state.partnershipVouchers = payload;
	},
	setCustomizableCards(state, payload) {
		state.customizableCards = payload;
	},
	setPlanIdentifiers(state, payload) {
		state.planIdentifiers = payload;
	},
	setFeaturedBanners(state, payload) {
		state.featuredBanners = payload.items;
		state.featuredBannersTotalItems = payload.totalItems;
	}
}

export default mutations
