<template lang="pug">
h5.text-left Performance
p-toolbar
  template(#start="")
    p-selectbutton(
      v-model="userType",
      :options="userTypeOptions",
      optionLabel="name",
      optionValue="value",
      @change="handleChangeUserType"
    )
    p-selectbutton.ml-2(
      v-model="periodFrequency",
      :options="periodFrequencyOptions",
      optionLabel="name",
      optionValue="value",
      @change="handleChangeFrequency"
    )
    p-calendar.ml-2(
      v-model="dates",
      selectionMode="range",
      :manualInput="false",
      showIcon=""
    )
  template(#end="")
      p-button.icon-only(icon="pi pi-refresh", @click="refreshMetrics()")
      p-button.ml-2(
        icon="pi pi-external-link",
        label="Exportar",
        @click="exportCSV($event)"
      )
p-table(
  :value="metrics",
  stripedRows="",
  showGridLines="",
  :loading="isLoading",
  responsiveLayout="scroll",
  tableStyle="min-width: 100rem",
  ref="metricsDT"
)
  p-columngroup(type='header')
    p-row
      p-column.col__header(header="Período", :rowspan="2")
      p-column.col__header(header="MAU", :rowspan="2")
      p-column.col__header(header="Gratuitos", :rowspan="2")
      p-column.col__header(header="Pagantes", :rowspan="2")
      p-column.col__header(header="Cadastros", :colspan="2")
      p-column.col__header(header="Novos Assinantes", :rowspan="1", :colspan="5")
      p-column.col__header(header="Retornos", :rowspan="2")
      p-column.col__header(header="Expirados" :rowspan="2")
      p-column.col__header(header="Cancelados" :rowspan="2")
      p-column.col__header(header="Inativos", :rowspan="1", :colspan="3")
      p-column.col__header(header="Solicitações de Cancelamento", :colspan="2")
      p-column.col__header(header="CHURN", :rowspan="2")
      p-column.col__header(header="Rotatividade", :rowspan="2")
      p-column.col__header(header="Conversão", :rowspan="2")
      p-column(header="Lifetime", :rowspan="2")
    p-row
      p-column(header="Totais")
      p-column.col__sub-item(header="Novos")
      p-column(header="Totais")
      p-column(header="Mensais")
      p-column(header="Trimestrais")
      p-column(header="Semestrais")
      p-column.col__sub-item(header="Anuais")
      p-column(header="Totais")
      p-column(header="Gratuitos")
      p-column.col__sub-item(header="Pagantes")
      p-column(header="Solicitados")
      p-column.col__sub-item(header="Efetivados")


  p-column.col__period
    template(#body="item")
      span {{ getPeriod(item.data.period) }}

  p-column
    template(#body="item")
      span(v-if="userType ==='all'") {{ formatValues(item.data.total_montly_active_users) }}
      span(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.montly_active_professionals) }}
      span(v-else-if="userType ==='student'") {{ formatValues(item.data.montly_active_students) }}

  p-column
    template(#body="item")
      span(v-if="userType ==='all'") {{ formatValues(item.data.total_trial) }}
      span(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.trial_professionals) }}
      span(v-else-if="userType ==='student'") {{ formatValues(item.data.trial_students) }}

  p-column
    template(#body="item")
      span(v-if="userType ==='all'", :class="getGrowthClass(item)") {{ formatValues(item.data.total_subscribers) }}
      span(v-else-if="userType ==='nutritionist'", :class="getGrowthClass(item)") {{ formatValues(item.data.total_paying_professionals) }}
      span(v-else-if="userType ==='student'", :class="getGrowthClass(item)") {{ formatValues(item.data.total_paying_students) }}

  p-column
    template(#body="item")
      span(v-if="userType ==='all'") {{ formatValues(item.data.total_registrations) }}
      span(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.total_registrations - item.data.total_student_registrations) }}
      span(v-else-if="userType ==='student'") {{ formatValues(item.data.total_student_registrations) }}

  p-column
    template(#body="item")
      span(v-if="userType ==='all'") {{ formatValues(item.data.registrations) }}
      span(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.registrations - item.data.student_registrations)}}
      span(v-else-if="userType ==='student'") {{ formatValues(item.data.student_registrations) }}

  p-column
    template(#body="item")
      span.text-cyan-500(v-if="userType ==='all'") {{ formatValues((item.data.new_paying_professionals ?? 0) + (item.data.new_paying_students ?? 0))}}
      span.text-cyan-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.new_paying_professionals) }}
      span.text-cyan-500(v-else-if="userType ==='student'") {{ formatValues(item.data.new_paying_students) }}

  p-column
    template(#body="item")
      span.text-cyan-500(v-if="userType ==='all'") {{ formatValues(item.data.new_subscribers_monthly) }}
      span.text-cyan-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.new_paying_professionals_monthly) }}
      span.text-cyan-500(v-else-if="userType ==='student'") {{ formatValues(item.data.new_paying_students_monthly) }}

  p-column
    template(#body="item")
      span.text-cyan-500(v-if="userType ==='all'") {{ formatValues(item.data.new_paying_professionals_quaterly) }}
      span.text-cyan-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.new_paying_professionals_quaterly) }}
      span.text-cyan-500(v-else-if="userType ==='student'") -

  p-column
    template(#body="item")
      span.text-cyan-500(v-if="userType ==='all'") {{ formatValues(item.data.new_paying_professionals_semester) }}
      span.text-cyan-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.new_paying_professionals_semester) }}
      span.text-cyan-500(v-else-if="userType ==='student'") -

  p-column
    template(#body="item")
      span.text-cyan-500(v-if="userType ==='all'") {{ formatValues(item.data.new_subscribers_yearly) }}
      span.text-cyan-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.new_paying_professionals_yearly) }}
      span.text-cyan-500(v-else-if="userType ==='student'") {{ formatValues(item.data.new_paying_students_yearly) }}

  p-column
    template(#body="item")
      span.text-teal-700(v-if="userType ==='all'") {{ formatValues(item.data.returns) }}
      span.text-teal-700(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.returns_professionals) }}
      span.text-teal-700(v-else-if="userType ==='student'") {{ formatValues(item.data.returns_students) }}

  p-column(header="Expirados")
    template(#body="item")
      span.text-orange-300(v-if="userType ==='all'") {{ formatValues(item.data.expired) }}
      span.text-orange-300(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.expired_professionals) }}
      span.text-orange-300(v-else-if="userType ==='student'") {{ formatValues(item.data.expired_students) }}

  p-column(header="Cancelamentos")
    template(#body="item")
      span.text-red-500(v-if="userType ==='all'") {{ formatValues(item.data.cancellations) }}
      span.text-red-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.cancellations_professionals) }}
      span.text-red-500(v-else-if="userType ==='student'") {{ formatValues(item.data.cancellations_students) }}

  p-column(header="Inativos")
    template(#body="item")
      span.text-yellow-700(v-if="userType ==='all'") {{ formatValues(item.data.inactivations) }}
      span.text-yellow-700(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.inactivations_professionals) }}
      span.text-yellow-700(v-else-if="userType ==='student'") {{ formatValues(item.data.inactivations_students) }}

  p-column(header="Inativos Gratuitos")
    template(#body="item")
      span.text-yellow-700(v-if="userType ==='all'") {{ formatValues(item.data.inactivations_trial) }}
      span.text-yellow-700(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.inactivations_trial_professionals) }}
      span.text-yellow-700(v-else-if="userType ==='student'") {{ formatValues(item.data.inactivations_trial_students) }}

  p-column(header="Inativos Pagantes")
    template(#body="item")
      span.text-yellow-700(v-if="userType ==='all'") {{ formatValues(item.data.inactivations_paying) }}
      span.text-yellow-700(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.inactivations_paying_professionals) }}
      span.text-yellow-700(v-else-if="userType ==='student'") {{ formatValues(item.data.inactivations_paying_students) }}

  p-column
    template(#body="item")
      span.text-red-500(v-if="userType ==='all'") {{ formatValues(item.data.cancellation_requests) }}
      span.text-red-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.cancellation_requests_professionals) }}
      span.text-red-500(v-else-if="userType ==='student'") {{ formatValues(item.data.cancellation_requests_students) }}

  p-column
    template(#body="item")
      span.text-red-500(v-if="userType ==='all'") {{ formatValues(item.data.cancellation_effective_requests) }}
      span.text-red-500(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.cancellation_effective_requests_professionals) }}
      span.text-red-500(v-else-if="userType ==='student'") {{ formatValues(item.data.cancellation_effective_requests_students) }}

  p-column(header="CHURN")
    template(#body="item")
      span(v-if="userType ==='all'") {{ getChurn(item) }}
      span(v-else-if="userType ==='nutritionist'") {{ getChurn(item) }}
      span(v-else-if="userType ==='student'") {{ getChurn(item) }}

  p-column(header="Rotatividade")
    template(#body="item")
      span {{ getRotatividade(item) }}

  p-column(header="Conversão")
    template(#body="item")
      span(v-if="userType ==='all'") {{ ((item.data.registrations ? item.data.new_subscribers / item.data.registrations : 0) * 100).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}%
      span(v-else-if="userType ==='nutritionist'") {{ ((item.data.registrations ? item.data.new_paying_professionals / item.data.registrations : 0) * 100).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}%
      span(v-else-if="userType ==='student'") {{ ((item.data.student_registrations ? item.data.new_paying_students / item.data.student_registrations : 0) * 100).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}%

  p-column(header="LifeTime")
    template(#body="item")
      span(v-if="userType ==='all'") {{ formatValues(item.data.lifetime) }}
      span(v-else-if="userType ==='nutritionist'") {{ formatValues(item.data.professional_lifetime_value) }}
      span(v-else-if="userType ==='student'") {{ formatValues(item.data.student_lifetime_value) }}
</template>

<script>
import Vuex from "vuex";
const metrics = Vuex.createNamespacedHelpers("metrics");

export default {
  name: 'PerformanceMetricsPanel',
  data() {
    return {
      dates: null,
      userTypeOptions: [
        { name: "Todos", value: "all" },
        { name: "Nutricionista", value: "nutritionist" },
        { name: "Estudante", value: "student" },
      ],
      periodFrequencyOptions: [
        { name: "Mês", value: "monthly" },
        { name: "Dia", value: "daily" },
      ],
      userType: "all",
      periodFrequency: "monthly",
      monthlyGrowthGoal: 2,
      dailyGrowthGoal: 0.000657,
    }
  },
  computed: {
    ...metrics.mapGetters({
        metrics: "getMetrics",
        isLoading: "getIsLoading",
    }),
  },
  methods: {
    ...metrics.mapActions(["fetchMetrics"]),
    handleChangeUserType(event) {
      this.userType = event.value;
    },
    handleChangeFrequency(event) {
      if (event.value === this.lastPeriodFrequency) return;
      this.lastPeriodFrequency = event.value;
      this.fetchMetrics({
        countryCode: this.country,
        frequency: event.value,
        start: this.dates ? this.dates[0] : null,
        end: this.dates ? this.dates[1] : null,
      });
    },
    formatValues(value) {
      return value?.toLocaleString("pt-BR") ?? 0
    },
    refreshMetrics() {
      this.fetchMetrics({
        countryCode: this.country,
        frequency: this.lastPeriodFrequency,
        start: this.dates ? this.dates[0] : null,
        end: this.dates ? this.dates[1] : null,
      });
    },
    exportCSV() {
      this.$refs.metricsDT.exportCSV();
    },
    getPeriod(dateStr) {
      const date = new Date(dateStr);
      if (this.periodFrequency === "monthly")
        return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}`;
      else
        return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
    },
    getRotatividade(item) {
      const index = this.metrics.findIndex(
        (x) => x.period === item.data.period
      );

      if (index < this.metrics.length - 1) {
        const lastMetrics = this.metrics[index + 1];

        switch (this.userType) {
          case "all":
            return `${((item.data.cancellations - item.data.returns) / lastMetrics.total_subscribers).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}%`;
          case "nutritionist":
            if (lastMetrics.total_paying_professionals === 0) return "-";
            return `${((item.data.cancellations_professionals - item.data.returns_professionals) / lastMetrics.total_paying_professionals).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}%`;
          case "student":
            if (lastMetrics.total_paying_students === 0) return "-";
            return `${((item.data.cancellations_students - item.data.returns_students) / lastMetrics.total_paying_students).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}%`;
        }

      } else {
        return "-";
      }
    },
    getChurn(item) {
      try {
        const index = this.metrics.findIndex(
          (x) => x.period === item.data.period
        );


        if (index < this.metrics.length - 1) {
          const lastMetrics = this.metrics[index + 1];

          switch (this.userType) {
            default:
            case "all":
              return `${(((item.data.cancellation_effective_requests + item.data.cancellations) / lastMetrics.total_subscribers) * 100
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}%`;
            case "nutritionist":
              return `${(((item.data.cancellation_effective_requests_professionals + item.data.cancellations_professionals) / lastMetrics.total_paying_professionals) * 100
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}%`;
            case "student":
              return `${(((item.data.cancellation_effective_requests_students + item.data.cancellations_students) / lastMetrics.total_paying_students) * 100
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}%`;
          }
        } else {
          switch (this.userType) {
            default:
            case "all":
              return `${(
                (item.data.cancellations / item.data.total_subscribers) *
                100
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}%`;
            case "nutritionist":
              return `${(
                (item.data.cancellations_professionals /
                  item.data.paying_professionals) *
                100
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}%`;
            case "student":
              return `${(
                (item.data.cancellations_students / item.data.paying_paying_students) *
                100
              ).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}%`;
          }

        }
      } catch (err) {
        return "-";
      }
    },
    getGrowthPercent(item) {
      const index = this.metrics.findIndex(
        (x) => x.period === item.data.period
      );

      const currentSubscribers = parseInt(
        this.metrics[index].total_subscribers
      );
      let previousSubscribers = parseInt(this.metrics[index].total_subscribers);

      const previousMonthIndex = index + 1;

      if (previousMonthIndex < this.metrics.length) {
        previousSubscribers = parseInt(
          this.metrics[previousMonthIndex].total_subscribers
        );
      }

      const growth =
        parseFloat(
          (currentSubscribers - previousSubscribers) / previousSubscribers
        ) * 100;

      if (this.periodFrequency == "monthly") {
        return `${growth.toFixed(2)}%`;
      } else {
        return `${growth.toFixed(4)}%`;
      }
    },

    getGrowthClass(item) {
      const index = this.metrics.findIndex(
        (x) => x.period === item.data.period
      );
      const previousMonthIndex = index + 1;

      let currentSubscribers = 0;
      let previousSubscribers = 0;

      switch (this.userType) {
        case "all":
          currentSubscribers = parseInt(this.metrics[index].total_subscribers);
          previousSubscribers = parseInt(
            this.metrics[index].total_subscribers
          );
           if (previousMonthIndex < this.metrics.length) {
            previousSubscribers = parseInt(
              this.metrics[previousMonthIndex].total_subscribers
            );
          }
          break;
        case "nutritionist":
          currentSubscribers = parseInt(
            this.metrics[index].total_paying_professionals
          );
          previousSubscribers = parseInt(
            this.metrics[index].total_paying_professionals
          );
          if (previousMonthIndex < this.metrics.length) {
            previousSubscribers = parseInt(
              this.metrics[previousMonthIndex].total_paying_professionals
            );
          }
          break;
        case "student":
          currentSubscribers = parseInt(this.metrics[index].total_paying_students);
          previousSubscribers = parseInt(this.metrics[index].total_paying_students);
          if (previousMonthIndex < this.metrics.length) {
            previousSubscribers = parseInt(
              this.metrics[previousMonthIndex].total_paying_students
            );
          }
          break;
      }

      const growth = parseFloat(currentSubscribers / previousSubscribers) - 1;

      let growthClass;
      const goal =
        this.periodFrequency == "monthly"
          ? this.monthlyGrowthGoal
          : this.dailyGrowthGoal;

      if (growth > 0) {
        growthClass = "text-green-500";

        if (growth > goal) {
          growthClass += " font-bold";
        }
      } else {
        growthClass = "text-red-300";
      }

      return growthClass;
    },
  },
  mounted() {
    this.fetchMetrics({
      frequency: this.periodFrequency,
    });
  }
}
</script>

<style lang="scss">
.col__header {
  border-right: 1px solid #e9ecef;

  .p-column-header-content {
    display: block;
    width: 100%;

    span {
      display: block;
      width: auto;
      text-align: center;
    }
  }
}
.col__sub-item {
  border-right: 1px solid #e9ecef;
}
.col__period {
  display: block;
  width: max-content;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
}

</style>
