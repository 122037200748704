import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
	async updateSubscriptionConfiguration({ commit }, data) {
		const { basic, student, premium } = data
		
		const basicReq = api.put(`/api/v1/subscription-configurations/${basic.id}`, basic.data);
		const studentReq = api.put(`/api/v1/subscription-configurations/${student.id}`, student.data);
		const premiumReq = api.put(`/api/v1/subscription-configurations/${premium.id}`, premium.data);

		return req(
			Promise.all([basicReq, studentReq, premiumReq]),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Atualização do Nutricionista",
					detail: "Limite de notificações atualizado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchSubscriptionConfiguration({ commit }) {
		return req(
			api.get('/api/v1/subscription-configurations/'),
			response => {
				commit('setSubscriptionConfiguration', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
}

export default actions;
