export const Role = {
    Admin: 'admin',
    CustomerService: 'customer_service',
	Analyst: 'analyst'
}

export function RolesOption(role) {
    const options = [
        { name: 'Atendimento ao Cliente', value: 'customer_service' },
		{ name: 'Analista', value: 'analyst' }
    ]

    if (role === Role.Admin) {
        options.push({ name: 'Admin', value: 'Admin' })
    }

    return options
}

export function RoleName(role) {
	switch (role) {
		case Role.Admin:
			return 'Admin'
		case Role.CustomerService:
			return 'Atendimento ao Cliente'
		case Role.Analyst:
			return 'Analista'
	}
}
