import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  isLoading: false,
  subscriptionConfiguration: null,

}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
