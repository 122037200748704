import { req } from '@/utils'
import api from '@/utils/api'

const actions = {
	isRegistered: async ({ commit }, email) => {
		return req(
			api.get(`/api/v1/accounts/is-registered/${email}`),
			() => { },
			isLoading => commit('setLoading', isLoading),
		)
	},
	fetchTransactions: async ({ state, commit }, query) => {
		let params;
		if (query) {
			params = {
				...query,
			}
		}
		else {
			params = {
				...state.filters,
				...state.pagination
			}
		}

		return req(
			api.get('/api/v1/transaction', { params: params }),
			response => {
				commit('setTotalItems', response.data.total_items)
				commit('setTransactions', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	}
}

export default actions;
