import { req } from '@/utils'
import api from '@/utils/api'
import bus from '@/events'
import eventTypes from "@/events/events"

const actions = {
	async addGift({ commit }, data) {
		const formData = new FormData();
		formData.append('date', data.date.toISOString());
		formData.append('title', data.title);
		formData.append('description', data.description);
		formData.append('redirectLink', data.link);
		formData.append('special', data.special);

		if(data.image) {
			formData.append('file', data.image, data.image.name);
		}

		return req(
			api.post('/api/v1/gift-configuration', formData),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Calendário de Presentes",
					detail: "Presente adicionado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchGifts({ commit }) {
		return req(
			api.get('/api/v1/gift-configuration?take=100'),
			response => {
				commit('setGifts', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async removeGift({ commit }, data) {
		return req(
			api.delete(`/api/v1/gift-configuration/${data.id}`, data),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Calendário de Presentes",
					detail: "Presente removido com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async addPartnershipVoucher({ commit }, data) {
		const formData = new FormData();

		formData.append('title', data.title);
		formData.append('redirectLink', data.redirectUrl);
		formData.append('description', data.description);
		formData.append('descriptionPreview', data.preview);
		formData.append('code', data.code);

		if(data.logo) {
			formData.append('file', data.logo, data.logo.name);
		}

		return req(
			api.post(`/api/v1/external-voucher`, formData),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cupons de Parceiros",
					detail: "Cupom adicionado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchPartnershipVouchers({ commit }) {
		return req(
			api.get('/api/v1/external-voucher?take=100'),
			response => {
				commit('setPartnershipVouchers', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async removePartnershipVoucher({ commit }, data) {
		return req(
			api.delete(`/api/v1/external-voucher/${data.id}`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Calendário de Presentes",
					detail: "Presente removido com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async addCustomizableCard({ commit }, data) {
		const formData = new FormData();

		formData.append('title', data.title);
		formData.append('redirectLink', data.link);

		if(data.thumb) {
			formData.append('file', data.thumb, data.thumb.name);
		}

		return req(
			api.post(`/api/v1/customizable-card`, formData),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cards Personalizaveis",
					detail: "Card adicionado com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async fetchCustomizableCards({ commit }) {
		return req(
			api.get('/api/v1/customizable-card?take=100'),
			response => {
				commit('setCustomizableCards', response.data.items)
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
	},
	async removeCustomizableCard({ commit }, data) {
		return req(
			api.delete(`/api/v1/customizable-card/${data.id}`),
			() => {
				bus.emit(eventTypes.NOTIFY, {
					severity: "success",
					summary: "Cards Personalizaveis",
					detail: "Card removido com sucesso"
				})
			},
			isLoading => {
				commit('setIsLoading', isLoading)
			}
		)
  },
  async fetchFeaturedBanners({ commit }, params) {
    return req(
      api.get('/api/v1/marketing/featured-banners', { params: params }),
      response => {
        commit('setFeaturedBanners', response.data)
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },
  async addFeaturedBanner({ commit }, data) {
    const formData = new FormData();
	formData.append('theme', data.theme);
    formData.append('text', data.text);
    formData.append('redirectLink', data.redirect_link);
    formData.append('backgroundColor', data.background_color);
    formData.append('startAt', data.start_at ? data.start_at.toISOString() : null);
    formData.append('endAt', data.end_at ? data.end_at.toISOString() : null);

    formData.append('fileImageDesktop', data.image_desktop, data.image_desktop.name);
		formData.append('fileImageMobile', data.image_mobile, data.image_mobile.name);

    return req(
      api.post(`/api/v1/marketing/featured-banners`, formData),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Banners em Destaque",
          detail: "Banner adicionado com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      },
      onError => {
        let err = onError.response
        if ([409].includes(err.status)) {
          bus.emit(eventTypes.NOTIFY, {
            severity: "error",
            summary: "Banners em Destaque",
            detail: "Já existe um banner cadastrado para esse período"
          });
        }
      }
    )
  },
  async removeFeaturedBanner({ commit }, data) {
    return req(
      api.delete(`/api/v1/marketing/featured-banners/${data.id}`),
      () => {
        bus.emit(eventTypes.NOTIFY, {
          severity: "success",
          summary: "Banners em Destaque",
          detail: "Banner removido com sucesso"
        })
      },
      isLoading => {
        commit('setIsLoading', isLoading)
      }
    )
  },

	async fetchPlanIdentifiers ({ commit }) {
		return req(
			api.get('/api/v1/marketing/plan-identifiers'),
			response => {
				commit("setPlanIdentifiers", response.data)
			},
		)
	},
}

export default actions;
