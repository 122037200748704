<template lang='pug'>
p-dialog(
	v-model:visible='dialogShow',
	:breakpoints='{"960px": "40vw", "640px": "50vw"}',
	:style='{width: "30vw"}',
	modal,
	@hide='handleCancel'
)
	template(#header='')
		h3 Confirmar ação

	p {{message}}

	template(#footer='')
		p-button.p-button-text(
			icon='pi pi-times',
			label='Cancelar',
			severity='danger',
			@click='handleCancel'
		)
		p-button.p-button-text(
			label='Confirmar',
			icon='pi pi-check',
			severity='success',
			@click='handleConfirm'
		)
</template>

<script>
import eventTypes  from "@/events/events"

export default {
	name: 'ConfirmationDialog',
	data() {
		return {
			dialogShow: false,
			message: 'Confirmar?',
			confirmHandler: null,
			cancelHandler: null
		}
	},
	methods: {
		handleConfirm() {
			if (this.confirmHandler) this.confirmHandler();
			this.closeDialog();
		},
		handleCancel() {
			if (this.cancelHandler) this.cancelHandler();
			this.closeDialog();
		},
		closeDialog() {
			this.$nextTick(() => {
				this.dialogShow = false;
			})
		}
	},
	mounted() {
		this.$bus.on(eventTypes.ASK_CONFIRMATION, ({ message = 'Confirmar?', confirmHandler = null, cancelHandler = null }) => {
			this.message = message;
			this.confirmHandler = confirmHandler;
			this.cancelHandler = cancelHandler;
			this.$nextTick(() => {
				this.dialogShow = true;
			})
		})
	},
	beforeUnmount () {
		this.$bus.off(eventTypes.ASK_CONFIRMATION)
	}
}
</script>
