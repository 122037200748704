const getters = {
	getFilter(state){
		return state.filters
	},
	getPagination(state) {
		return state.pagination
	},
	getPatients(state) {
		return state.patient
	},
	getIsLoading(state) {
		return state.loading
	},
	getTotalItems(state) {
		return state.totalItems
	},
}

export default getters
