const getters = {
    getLaminasFilter(state){
		return state.laminasFilters
	},
    getTagsFilter(state){
		return state.tagsFilters
	},
    getIsLoading(state){
        return state.isLoading
    },
    getPagination(state){
        return state.pagination
    },
    getLaminas(state){
        return state.laminas
    },
    getTags(state){
        return state.tags
    },
    getTotalItemsLaminas(state){
        return state.total_items_laminas
    },
}

export default getters