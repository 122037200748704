const mutations = {
	setFilter(state, payload) {
		state.filters = payload
	},
	setFiltersInvoicesFalied(state, payload) {
		state.filtersInvoiceFalied = payload
	},
	setPaginationSkip(state, payload) {
		state.pagination.skip = payload
	},
	setPaginationTake(state, payload) {
		state.pagination.take = payload
	},
	setTotalItems(state, payload) {
		state.totalItems = payload
	},
	setNutritionists(state, payload) {
		state.nutritionists = payload
	},
	setSelectedNutritionist(state, payload) {
		state.selectedNutritionist = payload
	},
	setUniversities(state, payload){
		state.universities = payload
	},
	setStats(state, payload) {
		state.stats = payload
	},
	setIsLoading(state, payload) {
		state.loading = payload
	},
	setIsLoadingUniversity(state, payload) {
		state.loadingUniversity = payload
	},
	setIsLoadingUpdateNutritionist(state, payload) {
		state.loadingUpdatingNutritionis = payload
	},
	setIsLoadingUpdateSubscriptionExpireAt(state, payload) {
		state.loadingSubscriptionExpireAt = payload
	},
	setIsLoadingCancelSubscription(state, payload) {
		state.loadingCancelSubscription = payload
	},
	setIsLoadingNutritionistStats(state, payload) {
		state.loadingGettingStats = payload
	},
	setIsLoadingTSPayMigration(state, payload) {
		state.loadingTSPayMigration = payload
	},
	setIsLoadingCache(state, payload) {
		state.loadingCache = payload
	},
	setIsLoadingDeleteNutritionist(state, payload) {
		state.loadingDeleteNutritionist = payload
	},
	setShowEditNutritionist(state, payload) {
		state.showEditNutritionist = payload
	},
	setInvoicesFalied(state, payload) {
		state.invoicesFalied = payload
	},
	setTotalItemsInvoicesFalied(state, payload) {
		state.totalItemsInvoicesFalied = payload
	},
	setIsLoadingInvoicesFalied(state, payload) {
		state.loadingInvoicesFalied = payload
	},
	setIsLoadingInvoiceFaliedHandled(state, payload) {
		state.loadingInvoiceFaliedHandled = payload
	},
}

export default mutations
