const getters = {
	getFilter(state){
		return state.filters
	},
	getPagination(state) {
		return state.pagination
	},
	getTotalItems(state) {
		return state.totalItems
	},
	getIsLoading(state) {
		return state.isLoading;
	},
	getNotificationLimit(state) {
		return state.notificationLimit;
	},
	getPurchaseLogs(state) {
		return state.purchaseLogs;
	},
	getSentRecord(state) {
		return state.sentRecord;
	}
}

export default getters
