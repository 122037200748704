export async function req(promise, callback, isLoading, onError) {
    let response;

    isLoading && isLoading(true);

    try {
        response = await promise;
        callback(response);
    } catch (err) {
        response = err.response;
        onError && onError(err);
    }

    isLoading && isLoading(false);

    return response;
}

export function getText(value) {
    return value ? value : "-";
}

export function timeAgoOrUntil(value) {
  var date = new Date(value);

  if (date < new Date()) {
    return timeAgo(value);
  } else {
    return timeUntil(value);
  }
}

export function getDate(value) {
    return value ? new Date(value).toLocaleDateString() : "-";
}

export function getDateTime(value) {
  if (!value) return "-";

  const date = new Date(value);
  const lang = window.navigator.language;
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  return date.toLocaleDateString(lang, options);
}

export function getTime(value) {
  if (!value) return "-";

  const date = new Date(value);
  const lang = window.navigator.language;
  const options = {
    hour: "numeric",
    minute: "numeric"
  };

  return date.toLocaleTimeString(lang, options);
}


export function timeUntil(value) {
  let date = value ? new Date(value) : "-";
  if (date === "-") return date;

  const seconds = Math.floor((date - new Date()) / 1000);
  let interval = seconds / 31_536_000;

  const lang = window.navigator.language;
  let remaining = new Intl.RelativeTimeFormat(lang, { style: "long" });

  if (interval > 1) {
    return remaining.format(Math.floor(interval), "years");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return remaining.format(Math.floor(interval), "months");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return remaining.format(Math.floor(interval), "days");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return remaining.format(Math.floor(interval), "hours");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return remaining.format(Math.floor(interval), "minutes");
  }
  return remaining.format(Math.floor(seconds), "seconds");
}

export function timeAgo(value) {
  let date = value ? new Date(value) : "-";
  if (date === "-") return date;

  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31_536_000;

  const lang = window.navigator.language;
  let ago = new Intl.RelativeTimeFormat(lang, { style: "long" });

  if (interval > 1) {
    return ago.format(Math.floor(interval) * -1, "years");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return ago.format(Math.floor(interval) * -1, "months");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return ago.format(Math.floor(interval) * -1, "days");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return ago.format(Math.floor(interval) * -1, "hours");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return ago.format(Math.floor(interval) * -1, "minutes");
  }
  return ago.format(Math.floor(seconds) * -1, "seconds");
}

export function clearString(value) {
  return value.replace(/[^0-9]/g, '');
}

export function translatePlan(plan) {
  if (!plan) return '-';

  switch (plan) {
    case "promo_student_monthly_plan":
      return "Promocional Mensal (Estudante)";
    case "student_monthly_plan":
      return "Mensal (Estudante)";
    case "student_yearly_plan":
      return "Anual (Estudante)";
    case "monthly_plan":
    case "monthly":
      return "Mensal";
    case "quarterly_plan":
    case "quarterly":
      return "Trimestral";
    case "semester_plan":
    case "semester":
      return "Semestral";
    case "yearly_plan":
    case "yearly":
      return "Anual";
    default:
      return plan;
  }
}

