import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
    isLoading: false,
    laminasFilters: {
      search: null,
      tags: null,
      type: null
    },
    tagsFilters: {
      search: null,
    },
    pagination: {
      skip: 0,
      take: 25
    },
    laminas: [],
    total_items_laminas: 0,
    tags: [],
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
