const getters = {
	getPagination(state) {
		return state.pagination
	},
	getTotalItems(state) {
		return state.totalItems
	},
	getUsers(state) {
		return state.users
	},
	getIsLoading(state) {
		return state.loading
	}
}

export default getters
